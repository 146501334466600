import React, { FC } from 'react';
import { Card, CardActionArea, CardMedia, Grid, Typography } from '@material-ui/core';
import { styled } from '@cappex/theme';
import HeartButton from './HeartButton';
import { FloatingCloseButton } from '@cappex/components';
import { AutomationNameDefault } from '@util/automation';
import defaultCollegeHero from '@common/assets/college/default-college-hero.jpg';
import { useHistory } from 'react-router-dom';
import useViewDependentLink, { ViewDependentLinkType } from '@util/studentContext/util/useViewDependentLink';

const CollegeCard = styled(Card)`
	position: relative;
	max-height: 6.5rem;
`;

const HeartButtonStyled = styled(HeartButton)`
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
`;

interface CollegeCardProps {
	collegeId: number;
	collegeName: string;
	collegeHeroImage?: string;
	unsaveCollege?: (collegeId: number) => void;
	saveCollege: (collegeId: number) => void;
	dismissCollege: (collegeId: number) => void;
	selected?: boolean;
	displayDismiss?: boolean;
}

const CollegeCardComponent: FC<CollegeCardProps> = ({
	selected,
	saveCollege,
	unsaveCollege,
	dismissCollege,
	displayDismiss,
	collegeId,
	collegeName,
	collegeHeroImage,
}) => {
	const history = useHistory();
	const profileLinkBase = useViewDependentLink(ViewDependentLinkType.COLLEGE_PROFILE);

	return (
		<div>
			<Grid
				container
				spacing={1}
				direction="column"
				data-qa={AutomationNameDefault.collegeCarouselCard}
			>
				<Grid item xs>
					<CollegeCard>
						<CardActionArea
							disableRipple
							onClick={() => history.push(`/${profileLinkBase}/${collegeId}/${collegeName}`)}
						>
							<CardMedia
								component="img"
								src={collegeHeroImage || defaultCollegeHero}
								title={collegeName}
								data-qa={AutomationNameDefault.collegeCarouselCardHero}
							/>
						</CardActionArea>
						{displayDismiss && (
							<FloatingCloseButton
								onClick={() => dismissCollege(collegeId)}
								position={['top', 'left']}
								size={1.5}
								variant="inverse"
								disablePadding
							/>
						)}
						<HeartButtonStyled
							data-qa={AutomationNameDefault.collegeCarouselSave}
							id={`college-carousel-save-${collegeId}`}
							onClick={() => (selected ? unsaveCollege(collegeId) : saveCollege(collegeId))}
							size="medium"
							selected={selected}
						/>
					</CollegeCard>
				</Grid>
				<Grid item xs>
					<Typography
						variant="body2"
						color="textPrimary"
						data-qa={AutomationNameDefault.collegeCarouselCardTitle}
					>
						{collegeName}
					</Typography>
				</Grid>
			</Grid>
		</div>
	);
};

export default CollegeCardComponent;

import React, { FC, useContext, useState } from 'react';
import checkLockout from '@util/lockout';
import { Grid, Typography, Box } from '@material-ui/core';
import RegisterButton from '@src/common/components/RegisterButton';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import {
	RequestSourceIdentifier,
	checkForFormError,
	FORM_NAME,
	getFormErrors,
} from '@cappex/request';
import { StepContainerProps } from '@src/common/util/steps';
import { FormContext } from '@util/validation/form';
import { styled } from '@cappex/theme';
import { DataFlowStepComponent } from '../../constants/types';
import DataFlowContext from '../../util/DataFlowContext';
import DataFlowContainer from '../DataFlowContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import LevelOfDegreeSeekingSelect from '@src/common/components/LevelOfDegreeSeekingSelect';
import { FormNames, ListFormNames, DataFlowContainerVariant } from '@cappex/constants';
import { SubForm } from '@src/common/components/BaseValidationForm';
import {
	StudentCollegeListTypeTrackingValue,
	StudentCollegeListLocationTrackingValue,
} from '@src/common/util/studentcollege';
import MajorFormInput from '@src/common/components/MajorFormInput';
import MultiCollegeInput from '@src/common/components/MultiCollegeInput';

const PaddedBox = styled(Box)`
	padding-left: 1rem;
	padding-right: 1rem;
`;

const SearchWrapper = styled.div`
	margin-bottom: 0.5rem;
`;

const GutterBottomTypography = styled(Typography)`
	margin-bottom: 1rem;
`;

const AlrScholarshipGpaPage: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	data: { topMedia, currentStep, totalSteps },
	active,
	complete,
	customLogoUrl,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { setFormErrors } = useContext(FormContext);
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);

	const [submitDisabled, setSubmitDisabled] = useState(false);

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	const onClick = () => {
		setPreHook(() => () => {
			setSubmitDisabled(true);
		});

		setPostHook(() => data => {
			if (data.meta.success) {
				complete();
				setSubmitDisabled(false);
			} else {
				throw data;
			}
		});

		setErrorHook(() => err => {
			setSubmitDisabled(false);
			let data;
			if (err.response && err.response.source === RequestSourceIdentifier) {
				// If this is coming from the request util
				data = { meta: { success: false } };
			} else {
				data = err;
			}

			const lockedOut = checkLockout(data);
			if (!lockedOut) {
				setSubmitDisabled(false);
				const errors = getFormErrors(data);

				setFormErrors(errors);

				if (checkForFormError(errors)) {
					openErrorSnack(errors[FORM_NAME]);
				}
			}
		});
		return true;
	};

	return (
		<DataFlowContainer
			variant={DataFlowContainerVariant.CENTERED}
			topMedia={topMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<Grid container direction="column" spacing={3}>
				<SubForm name="student">
					<Grid item>
						<Typography variant="h6">What type of degree are you thinking about?</Typography>
						<SubForm name="studentInfoForm">
							<LevelOfDegreeSeekingSelect
								label="Level of Degree"
								name={FormNames.levelOfDegreeSeekingId}
								required={active}
							/>
						</SubForm>
					</Grid>
				</SubForm>
				<Grid item xs={12}>
					<GutterBottomTypography variant="h6">Have any schools in mind?</GutterBottomTypography>
					<SearchWrapper>
						<MultiCollegeInput
							zindexInner={1}
							typeTrackingValue={StudentCollegeListTypeTrackingValue.SEARCH_BAR}
							locationTrackingValue={StudentCollegeListLocationTrackingValue.REGISTRATION_PATH}
						/>
					</SearchWrapper>
				</Grid>
				<Grid item>
					<SubForm distinct name="collegePreferences">
						<Typography variant="h6">What major are you interested in studying?</Typography>
						<MajorFormInput subText="Select up to 5" name={ListFormNames.majorCipCodes} />
					</SubForm>
				</Grid>
				<Grid item>
					<RegisterButton md={12} submitDisabled={submitDisabled} onClick={onClick}>
						Next
						<PaddedBox>
							<FontAwesomeIcon icon={faArrowRight} />
						</PaddedBox>
					</RegisterButton>
				</Grid>
			</Grid>
		</DataFlowContainer>
	);
};
export default AlrScholarshipGpaPage;

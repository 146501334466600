import React, { FC, useEffect, useState, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import LoadingComponent from '@src/common/components/LoadingComponent';
import { DataFlowStepComponent } from '../../constants/types';
import { StepContainerProps } from '@src/common/util/steps';
import request, {
	JsonAcceptHeader,
	JsonContentTypeHeader,
	RequestMethod,
	WebResponse,
} from '@cappex/request';
import getEndpoint, { FormKeyedData } from '@util/request';
import useUrlParam from '@util/hooks/useUrlParam';
import { Cbo } from '@util/cbo/constants';
import { createCboSpecificConfig } from '../../../registration/constants/cboSpecificConfig';
import HiddenInput from '@src/common/components/HiddenInput';
import { FormNames, ListFormNames } from '@cappex/constants';
import { SubForm } from '@src/common/components/BaseValidationForm';

type CboWebResponse = WebResponse<FormKeyedData, Cbo>;

type CboSpecificLandingPageProps = DataFlowStepComponent<any, any> & StepContainerProps;

const CboSpecificLandingPage: FC<CboSpecificLandingPageProps> = ({ complete, active }) => {
	const cboId = useUrlParam('cboid');
	const [shouldRedirect, setShouldRedirect] = useState(!cboId);

	const cboInputValue = useMemo(() => [cboId], [cboId]);

	useEffect(() => {
		const fetchCboData = async () => {
			try {
				const { data } = await request<CboWebResponse>({
					url: getEndpoint('/public/cbo/v1/getCboById/', [
						{
							name: 'id',
							value: cboId,
						},
					]),
					method: RequestMethod.GET,
					withCredentials: true,
					headers: [JsonAcceptHeader, JsonContentTypeHeader],
				});

				if (data.response.data && data.response.data.enabled) {
					complete(
						{ newConfig: createCboSpecificConfig(data.response.data.organizationName) },
						true
					);
				} else {
					setShouldRedirect(true);
				}
			} catch (err) {
				setShouldRedirect(true);
			}
		};

		if (active && !shouldRedirect) {
			fetchCboData();
		}
	}, [active, cboId, complete, shouldRedirect]);

	return (
		<>
			{shouldRedirect ? <Redirect to="/register/cbogeneral/create" /> : <LoadingComponent />}
			{cboId && (
				<SubForm name="student">
					<SubForm name={FormNames.studentCboForm}>
						<HiddenInput
							name={ListFormNames.studentCbos}
							initialValue={cboInputValue}
							automationName="hidden-input"
						/>
					</SubForm>
				</SubForm>
			)}
		</>
	);
};

export default CboSpecificLandingPage;

import React, { useEffect, FC, useState, useContext } from 'react';
import LoadingComponent from '@src/common/components/LoadingComponent';
import { StepContainerProps } from '@src/common/util/steps';
import { DataFlowStepComponent } from '../../dataflow/constants/types';
import getEndpoint from '@src/common/util/request';
import { useHistory, useRouteMatch } from 'react-router-dom';
import request, { RequestMethod } from '@cappex/request';
import { QuizContext, QuizEventType } from '@src/common/util/quiz';

const FlexibleRegistrationFetchPage: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	complete,
}) => {
	const { dispatch } = useContext(QuizContext);
	const match = useRouteMatch<{ firstslug: string; secondslug: string }>(
		'/register/:firstslug/:secondslug'
	);
	const [latch, setLatch] = useState(false);
	const history = useHistory();

	useEffect(() => {
		if (latch) return;
		setLatch(true);
		request({
			url: getEndpoint('/data-flow/v1/config', [
				{ name: 'id', value: `${match.params.firstslug}.${match.params.secondslug}` },
			]),
			method: RequestMethod.GET,
			withCredentials: true,
		})
			.then(res => {
				const {
					data: { meta, response },
				} = res;
				if (meta.success === false || !response?.config) {
					// avoiding history.push to allow core paths to properly redirect
					window.location.href = '/register/core/main1';
				}

				dispatch({
					type: QuizEventType.NEW_RESULTS,
					resultSet: response?.config?.results,
				});
				complete({ newId: response?.id, newConfig: response?.config, newStep: 0 }, true);
			})
			.catch(() => {
				// avoiding window.location.href to prevent infinite loop
				history.push('/register/core/main1');
			});
	}, [complete, latch, dispatch, match.params.firstslug, match.params.secondslug, history]);

	return <LoadingComponent />;
};

export default FlexibleRegistrationFetchPage;

import * as R from 'ramda';
import addUpdateFields from '@util/form/formUtils';
import getEndpoint, { FormKeyedData } from '@util/request';
import request, {
	RequestMethod,
	JsonAcceptHeader,
	JsonContentTypeHeader,
	WebResponse,
} from '@cappex/request';
import {
	StudentCollegeConnection,
	StudentCollegeConnectionType,
	StudentCollegeListStatus,
	LevelOfInterest,
	StudentCollegeListTrackingData,
} from '..';

export const getList = (
	statuses: StudentCollegeListStatus[] = [],
	onFail: () => void = () => {}
): Promise<StudentCollegeConnection[]> =>
	request<WebResponse<FormKeyedData, StudentCollegeConnection[]>>({
		url: getEndpoint(
			'/college-list/v1/retrieve',
			statuses.map(value => ({
				value,
				name: 'collegeListStatuses',
			}))
		),
		method: RequestMethod.GET,
		withCredentials: true,
		headers: [JsonAcceptHeader, JsonContentTypeHeader],
	})
		.then(res => res.data)
		.then(data => {
			if (data.meta.success && !R.isNil(data.response)) {
				return data.response;
			}
			throw new Error();
		})
		.catch<StudentCollegeConnection[]>(() => {
			onFail();
			return [];
		});

export type UpdateStudentCollegeListFields = {
	collegeListStatus: StudentCollegeListStatus;
	levelOfInterest: LevelOfInterest;
	collegeApplicationStatus: string;
};

export const updateList = (
	connectionId: number,
	fields: Partial<UpdateStudentCollegeListFields>,
	onFail: (err: any) => void = () => {}
): Promise<void> => {
	const form = {
		id: connectionId,
		...addUpdateFields(fields),
	};

	return request<any>({
		url: getEndpoint('/college-list/v1/update'),
		method: RequestMethod.POST,
		withCredentials: true,
		data: form,
		headers: [JsonAcceptHeader, JsonContentTypeHeader],
	})
		.then(res => res.data)
		.then(data => {
			if (data.meta.success) {
				return undefined;
			}
			throw data;
		})
		.catch<void>(err => {
			onFail(err);
			return undefined;
		});
};

export const addToList = (
	newCollege: number,
	trackingData: StudentCollegeListTrackingData,
	connectionType: StudentCollegeConnectionType = StudentCollegeConnectionType.GENERAL,
	onFail: (err: any) => void = () => {}
): Promise<number> => {
	const form = {
		collegeId: newCollege,
		collegeListStatus: StudentCollegeListStatus.ACTIVE,
		collegeListConnectionType: connectionType,
		collegeListTrackingData: trackingData,
	};

	return request<WebResponse<FormKeyedData, number>>({
		url: getEndpoint('/college-list/v1/create'),
		method: RequestMethod.POST,
		data: form,
		withCredentials: true,
		headers: [JsonAcceptHeader, JsonContentTypeHeader],
	})
		.then(res => res.data)
		.then(data => {
			if (data.meta.success && !R.isNil(data.response)) {
				return data.response;
			}
			throw data;
		})
		.catch<number>(err => {
			onFail(err);
			return undefined;
		});
};

export const removeFromList = (
	connectionId: number,
	onFail: () => boolean = () => false
): Promise<boolean> => {
	const form = {
		id: connectionId,
	};

	return request<WebResponse<FormKeyedData, boolean>>({
		url: getEndpoint('/college-list/v1/delete'),
		method: RequestMethod.POST,
		data: form,
		headers: [JsonAcceptHeader, JsonContentTypeHeader],
		withCredentials: true,
	})
		.then(res => res.data)
		.then(data => {
			if (data.meta.success) {
				return true;
			}
			throw new Error();
		})
		.catch<boolean>(() => onFail());
};

import * as R from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { styled } from '@cappex/theme';
import { Button, Grid, Typography } from '@material-ui/core';
import MultiCollegeInput from '@src/common/components/MultiCollegeInput';
import { ListFormNames } from '@cappex/constants';
import { StepContainerProps } from '@src/common/util/steps';
import {
	StudentCollegeListLocationTrackingValue,
	StudentCollegeListTypeTrackingValue,
} from '@src/common/util/studentcollege';
import withStyleOptions from '@src/common/util/style/styleOptions';
import { FormContext } from '@src/common/util/validation/form';
import { createChancesConfig } from '../../../registration/constants/chancesConfig';
import { DataFlowStepComponent } from '../../constants/types';
import DataFlowContext from '../../util/DataFlowContext';
import DataFlowContainer from '../DataFlowContainer';
import { SubForm } from '@src/common/components/BaseValidationForm';
import MajorFormInput from '@src/common/components/MajorFormInput';
import AddressBlock from '@src/common/components/AddressBlock';
import BirthDateInput from '@src/common/components/BirthDateInput';
import { Student } from '@src/common/util/student/studentDataUtil';

type StudentCollegesForm = {
	studentColleges: string[];
};

const RegisterButton = withStyleOptions(Button);

const GutterBottomTypography = styled(Typography)`
	margin-bottom: 1rem;
`;

const SearchWrapper = styled.div`
	margin-bottom: 0.5rem;
`;

const ChancesAdditionalCollegesPage: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	data: {
		leftMedia,
		rightMedia,
		showLeftTextMediaMobile,
		hideLeftMediaImageMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
	},
	complete,
	reverse,
	active,
	customLogoUrl,
}) => {
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const { getValue } = useContext(FormContext);

	const [isButtonDisabled, setButtonDisabled] = useState(false);
	const [isReverseCalled, setIsReverseCalled] = useState(false);
	const [userData] = useState<Partial<Student>>({});

	const onClick = () => {
		setPreHook(() => () => {
			setButtonDisabled(true);
		});

		setPostHook(() => responseData => {
			if (responseData.meta.success) {
				complete();
				setButtonDisabled(false);
			}
		});

		setErrorHook(() => () => {
			setButtonDisabled(false);
		});
		return true;
	};

	const studentCollegeFormValues = useMemo(
		() => getValue(ListFormNames.studentColleges) as StudentCollegesForm,
		[getValue]
	);

	useEffect(() => {
		if (
			!isReverseCalled &&
			active &&
			(R.isEmpty(studentCollegeFormValues) || R.isEmpty(studentCollegeFormValues.studentColleges))
		) {
			setIsReverseCalled(true);
			reverse({ newConfig: createChancesConfig(), newStep: 0 });
		}
	}, [reverse, studentCollegeFormValues, isReverseCalled, active, getValue]);

	return (
		<DataFlowContainer
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			hideLeftMediaImageMobile={hideLeftMediaImageMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<Grid container justifyContent="center" spacing={4}>
				<Grid item xs={12}>
					<GutterBottomTypography variant="h6">
						Add up to 10 colleges you&apos;re considering
					</GutterBottomTypography>
					<SearchWrapper>
						<MultiCollegeInput
							typeTrackingValue={StudentCollegeListTypeTrackingValue.SEARCH_BAR}
							locationTrackingValue={StudentCollegeListLocationTrackingValue.REGISTRATION_PATH}
							multiFormInit
							zindexInner={6}
						/>
					</SearchWrapper>
				</Grid>
				<SubForm name="collegePreferences">
					<Grid item xs={12}>
						<Typography variant="h6">Majors</Typography>
						<MajorFormInput subText="Select up to 5" name={ListFormNames.majorCipCodes} />
					</Grid>
				</SubForm>
				<SubForm name="student">
					<SubForm name="studentAddressForm">
						<Grid item xs={12}>
							<Typography variant="h6">Home Address</Typography>
							<AddressBlock
								address1Label="Street Address"
								required={active}
								hideAddress2Input
								hideCityInput
								hideStateInput
								student={userData}
							/>
						</Grid>
					</SubForm>
					<SubForm name="studentDataForm">
						<Grid item xs={12}>
							<BirthDateInput
								required={active}
								id="registration__birth_date"
								label="Date of Birth"
							/>
						</Grid>
					</SubForm>
				</SubForm>
				<Grid item xs={12}>
					<RegisterButton
						data-qa="create-account-button"
						$noneTextTransform
						$boldFontWeight
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						disabled={isButtonDisabled}
						onClick={onClick}
					>
						{totalSteps === currentStep ? 'See my chances' : 'Next'}
					</RegisterButton>
				</Grid>
			</Grid>
		</DataFlowContainer>
	);
};

export default ChancesAdditionalCollegesPage;

import * as R from 'ramda';
import React, { FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { defaultComponents } from '@cappex/search';
import StudentCollegeSearch from '@src/features/collegelist/components/StudentCollegeSearch';
import { FormNames, ListFormNames } from '@cappex/constants';
import useFormValidation from '../util/hooks/useFormValidation';
import {
	StudentCollegeConnectionType,
	StudentCollegeListLocationTrackingValue,
	StudentCollegeListStatus,
	StudentCollegeListTrackingType,
	StudentCollegeListTypeTrackingValue,
} from '../util/studentcollege';
import { SubFormContext } from './BaseValidationForm';
import { validateRequired } from './BaseFormSelect';
import SEARCH_INDEX_NAMES from '@util/search/constants';

type MultiCollegeInputProps = {
	onSelect?: (collegeObject: object) => void;
	onRemove?: (collegeObject: object) => void;
	name?: ListFormNames | FormNames;
	required?: boolean;
	locationTrackingValue: StudentCollegeListLocationTrackingValue;
	typeTrackingValue: StudentCollegeListTypeTrackingValue;
	multiFormInit?: boolean;
	zindexInner?: number;
	extra?: {
		searchIndex?: string;
	};
};

const EMPTY_ARRAY = [];

type TrackingData = {
	[StudentCollegeListTrackingType.LOCATION]: StudentCollegeListLocationTrackingValue;
	[StudentCollegeListTrackingType.TYPE]: StudentCollegeListTypeTrackingValue;
};

const onChange = (
	alterCount: () => void,
	trackingData: TrackingData,
	collegeObjects: object[],
	setCollegeObjects: (collegeObjects: object[]) => void,
	formValue: any,
	setFormValue: (v: any) => void,
	status: StudentCollegeListStatus,
	collegeObject: { value: string }
) => {
	alterCount();

	const studentCollege = {
		collegeId: collegeObject.value,
		collegeListConnectionType: StudentCollegeConnectionType.GENERAL,
		collegeListStatus: status,
		levelOfInterest: null,
		collegeApplicationStatus: null,
		collegeListTrackingData: trackingData,
	};

	let currentStudentColleges = formValue[ListFormNames.studentColleges];

	if (currentStudentColleges !== undefined && currentStudentColleges.length > 0) {
		const oldStudentCollege = R.find(R.propEq('collegeId', collegeObject.value), collegeObjects);

		if (oldStudentCollege !== undefined) {
			const index = R.indexOf(oldStudentCollege, currentStudentColleges);
			currentStudentColleges[index] = studentCollege;

			setFormValue({
				...formValue,
				[ListFormNames.studentColleges]: currentStudentColleges,
			});
		} else {
			currentStudentColleges = [...currentStudentColleges, studentCollege];

			setFormValue({
				...formValue,
				[ListFormNames.studentColleges]: currentStudentColleges,
			});
		}
	} else {
		setFormValue({
			...formValue,
			[ListFormNames.studentColleges]: [studentCollege],
		});
	}

	setCollegeObjects([
		...R.reject(R.propEq('collegeId', collegeObject.value), collegeObjects),
		studentCollege,
	]);
};

const MultiCollegeInput: FC<MultiCollegeInputProps> = ({
	onSelect = () => {},
	onRemove = () => {},
	locationTrackingValue,
	typeTrackingValue,
	name = ListFormNames.studentColleges,
	required,
	multiFormInit = false,
	zindexInner = 6,
	extra: { searchIndex } = { searchIndex: SEARCH_INDEX_NAMES.COLLEGE.valueOf() },
}) => {
	const { path } = useContext(SubFormContext);

	const [collegeObjects, setCollegeObjects] = useState([]);
	const [activeCollegeCount, setActiveCollegeCount] = useState(0);

	const headerRef = useRef(null);

	const initialValueObj = useMemo(
		() => ({
			[name]: EMPTY_ARRAY,
		}),
		[name]
	);
	const validator = useCallback(input => validateRequired(name, required)(input), [name, required]);

	const { value, setValue } = useFormValidation({
		path,
		name,
		validator,
		initialValue: initialValueObj,
		fieldRef: headerRef,
		multiFormInit,
	});

	const filterValues = useMemo(
		() =>
			R.pipe(
				R.without(collegeObjects || []),
				R.pluck('collegeId')
			)((value[ListFormNames.studentColleges] as string[]) || []),
		[collegeObjects, value]
	);

	const trackingData: TrackingData = {
		[StudentCollegeListTrackingType.LOCATION]: locationTrackingValue,
		[StudentCollegeListTrackingType.TYPE]: typeTrackingValue,
	};

	return (
		<StudentCollegeSearch
			placeholderText="Search Schools"
			onItemSelect={collegeObject => {
				onChange(
					() => {
						setActiveCollegeCount(activeCollegeCount + 1);
					},
					trackingData,
					collegeObjects,
					setCollegeObjects,
					value,
					setValue,
					StudentCollegeListStatus.ACTIVE,
					collegeObject
				);
				onSelect(collegeObject);
			}}
			onItemRemove={collegeObject => {
				onChange(
					() => {
						setActiveCollegeCount(activeCollegeCount - 1);
					},
					trackingData,
					collegeObjects,
					setCollegeObjects,
					value,
					setValue,
					StudentCollegeListStatus.ARCHIVED,
					collegeObject
				);
				onRemove(collegeObject);
			}}
			transient={false}
			isMultiSelect
			stylingComponents={defaultComponents}
			disabled={activeCollegeCount >= 10}
			filterValues={filterValues}
			zindexInner={zindexInner}
			searchIndex={searchIndex}
		/>
	);
};

export default MultiCollegeInput;

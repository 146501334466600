import * as R from 'ramda';
import React, { FC, useCallback, useContext, useMemo, useRef } from 'react';
import { ListFormNames, ReferenceDataTypes } from '@cappex/constants';
import { styled } from '@cappex/theme';
import { OptionItem, ReactSelect } from '@cappex/search';
import { Grid, Typography } from '@material-ui/core';
import useCloudReferenceData from '@src/common/util/hooks/useCloudReferenceData';
import useFormValidation from '../util/hooks/useFormValidation';
import { SubFormContext } from './BaseValidationForm';
import { validateRequired } from '@common/components/BaseFormSelect';
import { FormFields } from '../util/validation/form';

const SearchWrapper = styled.div`
	margin-bottom: 0.5rem;
`;

interface AthleticsInputProps {
	label?: string;
	subText?: string;
	name?: string;
	required?: boolean;
	defaultValue?: string[];
}

const EMPTY_ARRAY = [];
const AthleticsInput: FC<AthleticsInputProps> = ({
	label,
	subText,
	name = ListFormNames.studentAthletics,
	required,
	defaultValue = EMPTY_ARRAY,
}) => {
	const { path } = useContext(SubFormContext);
	const headerRef = useRef(null);

	const validator = useCallback((input: FormFields) => validateRequired(name, required)(input), [
		name,
		required,
	]);

	const initialValueObj = useMemo(() => ({ [name]: defaultValue }), [defaultValue, name]);

	const { value, setValue } = useFormValidation({
		path,
		name,
		validator,
		initialValue: initialValueObj,
		fieldRef: headerRef,
	});

	const onSelect = (option: OptionItem) => {
		const current = value[name];
		setValue({ ...value, [name]: [...current, option.value] as string[] });
	};

	const onRemove = (option: OptionItem) => {
		setValue({
			...value,
			[name]: R.reject(val => val === option.value, value[name] as string[]) as string[],
		});
	};

	const [athletics] = useCloudReferenceData({
		dataType: ReferenceDataTypes.athletic,
	});

	const options = useMemo(() => athletics.map(item => ({ value: item.id, label: item.value })), [
		athletics,
	]);

	const selectedItems = useMemo(
		() =>
			options.filter(option => Array.isArray(value[name]) && value[name].includes(option.value)),
		[name, options, value]
	);

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Typography variant="h6" innerRef={headerRef}>
					{label}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body2" color="textSecondary">
					{subText}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<SearchWrapper>
					<ReactSelect
						id="Athletic-search"
						name={name}
						placeholderText="Search Athletics"
						value={selectedItems}
						isMultiSelect
						closeMenuOnSelect
						options={options}
						onItemSelect={onSelect}
						onItemRemove={onRemove}
					/>
				</SearchWrapper>
			</Grid>
		</Grid>
	);
};

export default AthleticsInput;

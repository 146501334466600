import React, {FC, useCallback, useMemo, useState} from 'react';
import {
	Box,
	Button,
	ButtonProps,
	Card,
	Grid,
	Typography,
	TypographyProps,
} from '@material-ui/core';
import { MagicLoginConfiguration } from '../util/types';
import { styled } from '@cappex/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getEndpoint from '@util/request';
import request, { JsonContentTypeHeader, RequestMethod } from '@cappex/request';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AutomationNameDefault, AutomationNameGeneric } from '@src/common/util/automation';
import convertEmailUuid from "@src/features/magiclogin/util/convertEmailUuid";

const Spaced = styled(Box)`
	padding: 0 0.5rem;
`;

const IconTypography = styled(Typography)`
	padding: 0.5rem;
`;

type RequestLinkBoxProps = MagicLoginConfiguration['boxMedia'] & {
	finish?: boolean;
	email?: string;
	emailUuid: string;
	iconColor?: TypographyProps['color'];
	queryParams?: { [key in string]: string };
};

type RequestLinkBoxDisplayProps = Omit<MagicLoginConfiguration['boxMedia'], 'icon'> & {
	action: () => void;
	iconColor: TypographyProps['color'];
	buttonType: ButtonProps['variant'];
	icon: IconProp;
};

const RequestLinkCard = styled(Card)`
	background-color: ${({ theme }) => theme.palette.base.main};
	padding: 2.5rem 1.5rem;
`;

const EMPTY = () => {};
export const getLinkRequest = (
	email: string,
	emailUuid: string,
	params: { [key in string]: string },
	onSuccess: () => void = EMPTY,
	onError: () => void = EMPTY
) => () => {
	let response;
	if (email) {
		response = request({
			url: getEndpoint('/public/magic/v1/email-request'),
			method: RequestMethod.POST,
			data: {
				email,
				...params,
			},
			withCredentials: true,
			headers: [JsonContentTypeHeader],
		});
	} else {
		const convertedEmailUuid = convertEmailUuid(emailUuid);
		response = request({
			url: getEndpoint('/public/magic/v1/request'),
			method: RequestMethod.POST,
			data: {
				externalId: convertedEmailUuid,
				...params,
			},
			withCredentials: true,
			headers: [JsonContentTypeHeader],
		});
	}
	response
		.then(res => {
			if (res.data.meta.success) {
				onSuccess();
				return;
			}
			onError();
		})
		.catch(() => {
			onError();
		});
};

const finishedConfig = {
	icon: faEnvelope,
	header: 'Ta-da! Check your email',
	subtext: 'Your sign-in link will be good for 24 hours.',
	buttonConfig: { text: 'Resend link' },
};

export const RequestLinkBoxDisplay: FC<RequestLinkBoxDisplayProps> = ({
	action,
	iconColor,
	buttonType,
	icon,
	header,
	subtext,
	buttonConfig,
}) => (
	<RequestLinkCard data-qa={AutomationNameDefault.magicLinkBox} elevation={0}>
		<Grid container spacing={4} direction="column" alignItems="stretch">
			<Grid item>
				<Grid container spacing={1} direction="column" alignItems="center">
					<Grid item>
						<IconTypography color={iconColor}>
							<FontAwesomeIcon icon={icon} size="2x" />
						</IconTypography>
					</Grid>
					<Grid item>
						<Typography variant="h5" color="textPrimary" data-qa={AutomationNameGeneric.headerText}>
							{header}
						</Typography>
					</Grid>
					{subtext && (
						<Grid item>
							<Typography
								variant="body1"
								color="textSecondary"
								align="center"
								data-qa={AutomationNameGeneric.text}
							>
								{subtext}
							</Typography>
						</Grid>
					)}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Button
					variant={buttonType}
					color="primary"
					onClick={action}
					fullWidth
					size="large"
					data-qa={`${AutomationNameDefault.magicLinkBox}-${AutomationNameGeneric.doneButton}`}
				>
					{buttonType === 'text' ? (
						buttonConfig.text
					) : (
						<>
							<Spaced>
								<FontAwesomeIcon icon={['fas', buttonConfig?.icon || 'envelope']} />
							</Spaced>
							{buttonConfig?.text || 'Email me a link'}
						</>
					)}
				</Button>
			</Grid>
		</Grid>
	</RequestLinkCard>
);

const RequestLinkBox: FC<RequestLinkBoxProps> = ({
	finish = false,
	email = '',
	emailUuid,
	queryParams,
	icon,
	iconColor = 'textSecondary',
	header,
	subtext,
	buttonConfig,
}) => {
	const [finished, setFinished] = useState(finish);

	const convertedEmailUuid = useMemo(() => convertEmailUuid(emailUuid), [emailUuid]);
	const doApiCall = useCallback(
		() => getLinkRequest(email, convertedEmailUuid, queryParams, () => setFinished(true))(),
		[email, convertedEmailUuid, queryParams]
	);

	if (finished) {
		return (
			<RequestLinkBoxDisplay
				action={doApiCall}
				icon={finishedConfig.icon}
				header={finishedConfig.header}
				subtext={finishedConfig.subtext}
				buttonConfig={finishedConfig.buttonConfig}
				buttonType="text"
				iconColor="primary"
			/>
		);
	}

	return (
		<RequestLinkBoxDisplay
			action={doApiCall}
			icon={['fal', icon]}
			header={header}
			subtext={subtext}
			buttonConfig={buttonConfig}
			buttonType="contained"
			iconColor={iconColor}
		/>
	);
};

export default RequestLinkBox;

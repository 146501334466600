import { FormNames, ListFormNames, SupportedCustomQuizDataFields } from '@cappex/constants';

const mapDataFieldToFormLocation = (dataField: string): (FormNames|ListFormNames)[] => {
	switch (dataField) {
		case SupportedCustomQuizDataFields.CURRENT_COLLEGE_ID : {
			return [FormNames.studentCollegeDataForm, FormNames.currentCollegeId];
		}
		case SupportedCustomQuizDataFields.EXPECTED_COLLEGE_GRAD_MONTH : {
			return [FormNames.studentCollegeDataForm, FormNames.collegeGradMonth];
		}
		case SupportedCustomQuizDataFields.EXPECTED_COLLEGE_GRAD_YEAR : {
			return [FormNames.studentCollegeDataForm, FormNames.collegeGradYear];
		}
		case SupportedCustomQuizDataFields.EXPECTED_COLLEGE_TRANSFER_TERM_ID : {
			return [FormNames.studentCollegeDataForm, FormNames.collegeTransferTermId];
		}
		case SupportedCustomQuizDataFields.EXPECTED_COLLEGE_TRANSFER_YEAR : {
			return [FormNames.studentCollegeDataForm, FormNames.collegeTransferYear];
		}
		case SupportedCustomQuizDataFields.COLLEGE_GPA : {
			return [FormNames.studentCollegeDataForm, FormNames.collegeGpa];
		}
		case SupportedCustomQuizDataFields.INTERESTED_IN_TRANSFER : {
			return [FormNames.studentCollegeDataForm, FormNames.openToTransfer];
		}
		case SupportedCustomQuizDataFields.ATTEND_RELIGIOUS_ID : {
			return [FormNames.studentInfoForm, FormNames.attendReligiousId];
		}
		case SupportedCustomQuizDataFields.DESIRED_COMPLETION_TIMEFRAME_ID : {
			return [FormNames.studentInfoForm, FormNames.desiredCompletionTimeframeId];
		}
		case SupportedCustomQuizDataFields.ENROLLED : {
			return [FormNames.studentInfoForm, FormNames.enrolled];
		}
		case SupportedCustomQuizDataFields.LAST_DEGREE_COMPLETED_ID : {
			return [FormNames.studentInfoForm, FormNames.lastDegreeCompletedId];
		}
		case SupportedCustomQuizDataFields.LEVEL_OF_DEGREE_SEEKING : {
			return [FormNames.studentInfoForm, FormNames.levelOfDegreeSeekingId];
		}
		case SupportedCustomQuizDataFields.MODALITY_IDS : {
			return [FormNames.studentInfoForm, ListFormNames.modalityIds];
		}
		case SupportedCustomQuizDataFields.SEEKING_MBA : {
			return [FormNames.studentInfoForm, FormNames.seekingMba];
		}
		case SupportedCustomQuizDataFields.SEEKING_TEST_OPTIONAL_ADMISSIONS : {
			return [FormNames.studentInfoForm, FormNames.seekingTestOptionalAdmissions];
		}
		case SupportedCustomQuizDataFields.START_TIMEFRAME_ID : {
			return [FormNames.studentInfoForm, FormNames.startTimeframeId];
		}
		case SupportedCustomQuizDataFields.STUDY_ABROAD_ID : {
			return [FormNames.studentInfoForm, FormNames.studyAbroadId];
		}
		case SupportedCustomQuizDataFields.YEARS_OF_WORK_EXPERIENCE : {
			return [FormNames.studentInfoForm, FormNames.yearsOfWorkExperience];
		}
		case SupportedCustomQuizDataFields.AREA_OF_INTEREST : {
			return [FormNames.studentAreaOfInterestForm, FormNames.studentAreaOfInterestId]
		}
		case SupportedCustomQuizDataFields.ACADEMIC_DISCIPLINE : {
			return [FormNames.studentAcademicDisciplineForm, FormNames.studentAcademicDisciplineIds]
		}
		default: {
			return null;
		}
	}
};

export default mapDataFieldToFormLocation;
import { ComponentType } from 'react';
import { StepComponentProps, stepToComponentMapping } from '@src/common/util/steps';
import ChancesAdditionalCollegesPage from '../components/steps/ChancesAdditionalCollegesPage';
import ChancesCollegeInfoPage from '../components/steps/ChancesCollegeInfoPage';
import ChancesLandingPage from '../components/steps/ChancesLandingPage';
import ChancesPersonalInfoPage from '../components/steps/ChancesPersonalInfoPage';
import ConsentStep from '../components/steps/ConsentStep';
import CreateAccountPage from '../components/steps/CreateAccountPage';
import FinalizeStep from '../components/steps/FinalizeStep';
import MatchesCreateAccountPage from '../components/steps/MatchesCreateAccountPage';
import MatchesInfoPage from '../components/steps/MatchesInfoPage';
import MatchesPreferencesPage from '../components/steps/MatchesPreferencesPage';
import ParentGuardianInvitePage from '../components/steps/ParentGuardianInvitePage';
import ScholarshipInfoPage from '../components/steps/ScholarshipInfoPage';
import SnackbarStep from '../components/steps/SnackbarStep';
import StudentCollegeInfoPage from '../components/steps/StudentCollegeInfoPage';
import StudentInvitePage from '../components/steps/StudentInvitePage';
import StudentMatchingInfoPage from '../components/steps/StudentMatchingInfoPage';
import StudentPersonalInfoPage from '../components/steps/StudentPersonalInfoPage';
import CboSpecificLandingPage from '../components/steps/CboSpecificLandingPage';
import RedirectStep from '../components/steps/RedirectStep';
import { DataFlowStepConfig, DataFlowStep } from '../constants/types';
import ScholarshipParentDataPage from '../components/steps/ScholarshipParentDataPage';
import CboGeneralCboSearchPage from '../components/steps/CboGeneralCboSearchPage';
import InviteFriendLinkPage from '../components/steps/InviteFriendLinkPage';
import FlexibleRegistrationFetchPage from '../../registration/components/FlexibleRegistrationFetchPage';
import FlexStep from '../components/steps/FlexStep';
import QuizModalStep from '../components/steps/QuizModalStep';
import AlrScholarshipDegreePage from '../components/steps/AlrScholarshipDegreePage';
import AlrScholarshipGpaPage from '../components/steps/AlrScholarshipGpaPage';
import AlrScholarshipInfoPage from '../components/steps/AlrScholarshipInfoPage';
import AlrScholarshipTimelinePage from '../components/steps/AlrScholarshipTimelinePage';
import FlexStepAccountCreation from '../components/steps/FlexStepAccountCreation';
import FlexStepReferralPage from '../components/steps/FlexStepReferralPage';
import FlexStepChancesLandingPage from '../components/steps/FlexStepChancesLandingPage';
import { DataFlowStepName } from '@cappex/constants';

const stepMapping: stepToComponentMapping<DataFlowStep> = (
	step: DataFlowStep
): ComponentType<StepComponentProps<any, any, DataFlowStepConfig>> => {
	switch (step.key) {
		case DataFlowStepName.CreateAccountName:
			return CreateAccountPage;
		case DataFlowStepName.ParentGuardianInviteName:
			return ParentGuardianInvitePage;
		case DataFlowStepName.StudentInviteName:
			return StudentInvitePage;
		case DataFlowStepName.ScholarshipInfoName:
			return ScholarshipInfoPage;
		case DataFlowStepName.ScholarshipParentData:
			return ScholarshipParentDataPage;
		case DataFlowStepName.StudentPersonalInfoName:
			return StudentPersonalInfoPage;
		case DataFlowStepName.StudentCollegeInfoName:
			return StudentCollegeInfoPage;
		case DataFlowStepName.StudentMatchingInfoName:
			return StudentMatchingInfoPage;
		case DataFlowStepName.ChancesLandingName:
			return ChancesLandingPage;
		case DataFlowStepName.ChancesCollegeInfoName:
			return ChancesCollegeInfoPage;
		case DataFlowStepName.ChancesAdditionalCollegesName:
			return ChancesAdditionalCollegesPage;
		case DataFlowStepName.ChancesPersonalInfoName:
			return ChancesPersonalInfoPage;
		case DataFlowStepName.MatchesPreferencesName:
			return MatchesPreferencesPage;
		case DataFlowStepName.MatchesInfoName:
			return MatchesInfoPage;
		case DataFlowStepName.MatchesCreateAccountName:
			return MatchesCreateAccountPage;
		case DataFlowStepName.CboGeneralCboSearchName:
			return CboGeneralCboSearchPage;
		case DataFlowStepName.CboSpecificLandingName:
			return CboSpecificLandingPage;
		case DataFlowStepName.InviteFriendLinkName:
			return InviteFriendLinkPage;
		case DataFlowStepName.FlexFetchName:
			return FlexibleRegistrationFetchPage;
		case DataFlowStepName.FlexStepName:
		case DataFlowStepName.JitFlexStepName:
			return FlexStep;
		case DataFlowStepName.FlexAccountCreation:
			return FlexStepAccountCreation;
		case DataFlowStepName.FlexReferralPage:
			return FlexStepReferralPage;
		case DataFlowStepName.FlexChancesLandingPage:
			return FlexStepChancesLandingPage;

		case DataFlowStepName.AlrScholarshipDegreeName:
			return AlrScholarshipDegreePage;
		case DataFlowStepName.AlrScholarshipGpaName:
			return AlrScholarshipGpaPage;
		case DataFlowStepName.AlrScholarshipInfoName:
			return AlrScholarshipInfoPage;
		case DataFlowStepName.AlrScholarshipTimelineName:
			return AlrScholarshipTimelinePage;

		case DataFlowStepName.Consent:
			return ConsentStep;
		case DataFlowStepName.Finalize:
			return FinalizeStep;
		case DataFlowStepName.SnackbarStepName:
			return SnackbarStep;
		case DataFlowStepName.Redirect:
			return RedirectStep;
		case DataFlowStepName.QuizResult:
			return QuizModalStep;

		default:
			// Throw if we don't know what the step is
			throw new Error(`Unknown Step Name: ${step.key}`);
	}
};

export default stepMapping;

import React, { FC, useContext, useEffect, useState } from 'react';
import { Checkbox, Grid, Typography } from '@material-ui/core';
import getConsentText, { ConsentTextResponse } from '@util/consent/getConsentText';
import { ListFormNames } from '@cappex/constants';
import { FormContext } from '@util/validation/form';

interface ConsentCheckboxAndTextProps {
	consentTextId: string;
	consentTypeId: string;
	fieldValue: any;
}

interface Consent {
	consentTextId: string;
	responseTypeId: string;
}

const getText = (consentTextId: string, consentTypeId: string) =>
	getConsentText(consentTextId, consentTypeId);

const ConsentCheckboxAndText: FC<ConsentCheckboxAndTextProps> = ({
	consentTextId,
	consentTypeId,
	fieldValue
}) => {
	const [consent, setConsent] = useState<ConsentTextResponse>(null);
	const [consentChecked, setConsentChecked] = useState(false);
	const { getValue, setFormValue } = useContext(FormContext);

	useEffect(() => {
		const fetchTextAsync = async () => {
			try {
				const text = await getText(consentTextId, consentTypeId);
				setConsent(text);
			} catch (error) {
				console.error('Error fetching consent text', error);
			}
		};

		fetchTextAsync();
	}, [consentTextId, consentTypeId]);

	useEffect(() => {}, [consentChecked, getValue]);

	const handleConsentChecked = (val: boolean) => {
		setConsentChecked(val);
		const currentConsents = getValue(ListFormNames.consents) as unknown as { consents: Consent[] };

		const newConsent = {
			consentTextId,
			responseTypeId: val ? '2' : '1',
			consentTypeId,
		};

		const index = currentConsents.consents.findIndex(consentValue =>
			consentValue.consentTextId === consentTextId && consentValue.responseTypeId === (val ? '1' : '2'));

		// There exists a consent for this typeId/textId
		if (index !== -1) {
			currentConsents.consents.splice(index, 1);

			// Only add new/updated consent if fieldValue is truthy, meaning user selected some value for the corresponding input
			if(fieldValue){
				currentConsents.consents.push(newConsent);
				setFormValue(ListFormNames.consents, currentConsents.consents);
			}
		} else {
			currentConsents.consents.push(newConsent);
			setFormValue(ListFormNames.consents, currentConsents.consents);
		}
	};

	return (
		consent && (
			<Grid container wrap="nowrap" spacing={2} alignItems="flex-start">
				<Grid item xs={2}>
					<Checkbox
						id="consentCheckBox"
						checked={consentChecked}
						onChange={(_, val) => handleConsentChecked(val)}
					/>
				</Grid>
				<Grid item xs={10}>
					<Typography variant="caption" color="inherit">
						{consent.text}
					</Typography>
				</Grid>
			</Grid>
		)
	);
};

export default ConsentCheckboxAndText;

import * as R from 'ramda';
import React, { FC, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { styled } from '@cappex/theme';
import { Skeleton } from '@material-ui/lab';
import { AutomationNameDefault } from '@util/automation';

const CARD_PADDING = '1rem';

const GridCardWrapper = styled.div`
	margin: 0.5rem ${CARD_PADDING};
`;

const StyledCardSkeleton = styled(Skeleton)`
	border-radius: 0.5rem;
`;

const skeletonObjArray = [
	{ id: 'abc' },
	{ id: 'def' },
	{ id: 'ghi' },
	{ id: 'jkl' },
	{ id: 'mno' },
];

export type CardSkeletonSettings = {
	variant: 'text' | 'rect' | 'circle';
	width: number | string;
	height: number | string;
};

type GenericCardGridProps = {
	cardComponenet?: React.ComponentType;
	cardData?: object[];
	maxCardsInGrid?: number;
	cardSkeletonSettings: CardSkeletonSettings;
};

const GenericCardGrid: FC<GenericCardGridProps> = ({
	cardComponenet: Card,
	cardData,
	maxCardsInGrid = 4,
	cardSkeletonSettings,
}) => {
	const cardDataToDisplay = useMemo(() => cardData && cardData.slice(0, maxCardsInGrid), [
		cardData,
		maxCardsInGrid,
	]);

	const showSkeleton = !cardDataToDisplay || R.isEmpty(cardData);

	return (
		<Grid container spacing={3}>
			{showSkeleton
				? skeletonObjArray.map((_, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<Grid item xs={6} sm={6} md={6} lg={6} key={index}>
							{' '}
							{}
							<StyledCardSkeleton
								variant={cardSkeletonSettings.variant}
								width={cardSkeletonSettings.width}
								height={cardSkeletonSettings.height}
								data-qa={AutomationNameDefault.genericCardGridSkeleton}
							/>
						</Grid>
				  ))
				: cardDataToDisplay.map((data, i) => (
						// eslint-disable-next-line react/no-array-index-key
						<Grid item xs={6} sm={6} md={6} lg={6} key={i}>
							{' '}
							{}
							<GridCardWrapper>
								<Card {...data} />
							</GridCardWrapper>
						</Grid>
				  ))}
		</Grid>
	);
};

export default GenericCardGrid;

import { styled } from '@cappex/theme';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, FormControl, FormHelperText, Grid, Typography } from '@material-ui/core';
import React, { FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { QuizContext, QuizEvent, QuizEventType } from '..';
import { AutomationNameDefault } from '../../automation';
import useFormValidation from '../../hooks/useFormValidation';
import { SubFormContext } from '@src/common/components/BaseValidationForm';
import useMergedFormQuizDispatch, { QuizDispatchableConfig } from '../util/useMergedFormQuizDispatch';
import * as R from 'ramda';
import { validateRequired } from '@src/common/components/BaseFormSelect';
import { QuizAnswerCustomMapping } from '@util/quiz/components/GenericImageSelect';
import { FormNames, SupportedCustomQuizDataFields } from '@cappex/constants';
import { FormContext } from '@util/validation/form';
import customQuizQuestionMappingNew from '@src/features/dataflow/util/customQuizQuestionMapping';

type SelectConfig = QuizDispatchableConfig & {
	label: string;
	id: string | number;
	// Technically 1 question can only map to 1 data field. Trying to 'future proof' by allowing multiple but change to single if too complex
	customMapping?: QuizAnswerCustomMapping[];
	name?: string;
};

type GenericSelectProps = {
	required?: boolean;
	label?: string;
	name?: string;
	extra?: {
		multi?: boolean;
		selectConfig: SelectConfig[];
	};
};

type SelectableTextProps = SelectConfig & {
	selected?: boolean;
	dispatch: (id: string | number, event: QuizEvent) => void;
};

const SelectableCard = styled(({ selected, ...props }) => <Card {...props} />)`
	position: relative;
	border: 1px solid
		${({ theme, selected }) => (selected ? theme.palette.primary.main : theme.palette.text.hint)};
	padding: 0.75rem 1rem 0.75rem 0.75rem;
	cursor: pointer;

	&:focus,
	&:hover {
		outline: 0;
		box-shadow: ${({ theme }) => theme.shadows[8]};
	}
`;
const CircleBox = styled(({ selected, ...props }) => <Box {...props} />)`
	height: 1.5rem;
	width: 1.5rem;
	border: 1px solid ${({ theme }) => theme.palette.primary.main};
	background: ${({ selected, theme }) =>
		selected ? theme.palette.primary.main : theme.palette.common.white};
	border-radius: 50%;
	overflow: hidden;
`;

const StyledIcon = styled(FontAwesomeIcon)`
	margin: auto;
	display: block;
	padding-top: 0.3rem;
`;

const SpaceBox = styled(Box)`
	margin-bottom: 1rem;
`;

const ErrorText = styled(FormHelperText)`
	margin-left: 0.75rem;
`;

const SelectCircle: FC<{ selected?: boolean }> = ({ selected }) => (
	<CircleBox selected={selected}>
		<StyledIcon icon={faCheck} color="white" />
	</CircleBox>
);

const SelectableText: FC<SelectableTextProps> = ({
	label,
	dispatch,
	selected,
	values,
	id,
	customMapping,
	name,
}) => {
	const { setFormValue, setError, getValue } = useContext(FormContext);

	const addToCustomMappingForm = () => {
		if (customMapping != null && customMapping.length > 0) {
			customMapping.forEach(mapping => {
				if (
					!Object.values(SupportedCustomQuizDataFields).includes(
						mapping.dataField as SupportedCustomQuizDataFields
					)
				) {
					setError(name, ['Attempting to set invalid data field']);
				}
				const paths = customQuizQuestionMappingNew(mapping.dataField)

				setFormValue(paths[paths.length-1], mapping.value);
			});
		}
	};

	const removeFromCustomMappingForm = () => {
		if (customMapping != null && customMapping.length > 0) {
			customMapping.forEach(mapping => {
				const { [mapping.dataField]: _, ...remainder } = getValue(FormNames.customQuizMappingForm)[
					FormNames.customQuizMappingForm
				];
				setFormValue(FormNames.customQuizMappingForm, remainder);
			});
		}
	};

	return (
		<Grid item xs={12}>
			<SelectableCard
				tabIndex={0}
				elevation={0}
				selected={selected}
				data-qa={AutomationNameDefault.selectableText}
				onClick={() => {
					dispatch(id, {
						type: selected ? QuizEventType.REVOKE : QuizEventType.SEND,
						values,
					});
					if (!selected) {
						addToCustomMappingForm();
					} else {
						removeFromCustomMappingForm();
					}
				}}
				onKeyDown={event => {
					// Count the press if it's spacebar or enter
					if (event.keyCode === 32 || event.keyCode === 13) {
						dispatch(id, {
							type: selected ? QuizEventType.REVOKE : QuizEventType.SEND,
							values,
						});
					}
				}}
			>
				<Grid container justifyContent="flex-start" alignItems="center" spacing={2} wrap="nowrap">
					<Grid item>
						<SelectCircle selected={selected} />
					</Grid>
					<Grid item>
						<Typography variant="body1" color="textPrimary">
							{label}
						</Typography>
					</Grid>
				</Grid>
			</SelectableCard>
		</Grid>
	);
};

const INTERNAL = 'internal_id';

const GenericTextSelect: FC<GenericSelectProps> = ({
	label,
	required = false,
	name,
	extra: { multi, selectConfig } = {
		multi: false,
		selectConfig: [],
	},
}) => {
	const { dispatch } = useContext(QuizContext);
	const { path } = useContext(SubFormContext);
	const listRef = useRef(null);

	const initialValueObj = useMemo(() => ({ [name]: [null] }), [name]);

	const validator = useCallback(input => validateRequired(name, required)(input), [name, required]);

	const { value, setValue, error } = useFormValidation({
		path,
		name,
		validator,
		fieldRef: listRef,
		initialValue: initialValueObj,
		removeUpdateFields: true,
	});

	// In case we have no name and don't want the form to be polluted with extra data
	const [nonFormValue, setNonFormValue] = useState({ [INTERNAL]: [null] });
	const selectedIds = R.isNil(name) ? nonFormValue[INTERNAL] : value?.[name] || [];

	const patchedDispatch = useMergedFormQuizDispatch({
		dispatch,
		multi,
		selectConfig,
		setValue: R.isNil(name) ? setNonFormValue : setValue,
		name: R.isNil(name) ? INTERNAL : name,
		selectedIds,
	});

	return (
		<SpaceBox>
			<Typography align="left" variant="h6">
				<b>{label}</b>
			</Typography>
			<FormControl fullWidth ref={listRef} error={!!error}>
				<Grid container spacing={2}>
					{selectConfig.map(({ values, id, label: textLabel, customMapping }, index) => (
						<SelectableText
							values={values}
							label={textLabel}
							dispatch={() =>
								patchedDispatch(id || index, {
									type: selectedIds.includes(id || index)
										? QuizEventType.REVOKE
										: QuizEventType.SEND,
									values,
								})
							}
							id={id || index}
							key={`${label}-${id}`}
							selected={selectedIds.includes(id || index)}
							customMapping={customMapping}
						/>
					))}
				</Grid>
				<ErrorText>{error}</ErrorText>
			</FormControl>
		</SpaceBox>
	);
};
export default GenericTextSelect;

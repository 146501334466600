import React, { FC, useMemo, useContext, useState, useEffect, useRef } from 'react';
import * as R from 'ramda';
import request, {
	WebResponse,
	RequestMethod,
	JsonContentTypeHeader,
	ErrorMessages,
} from '@cappex/request';
import getEndpoint, { FormKeyedData } from '@util/request';
import { SnackbarContext, SnackbarType } from '@src/common/components/SnackbarManager';
import useFailedAuth from '@util/auth/hooks/useFailedAuth';
import { StudentCollegeListContext, StudentCollegeConnection } from '@util/studentcollege';
import { WamcChanceGroupId, WamcIneligibleReason } from '@util/wamc/constants';
import CollegeRecommendationContext, {
	RecommendedCollegesByWamcGroup,
	HadRecommendationsByWamcGroup,
} from '..';
import { CollegeRecommendationResult } from '../constants';
import { DismissalType } from '../../collegedismissal/CollegeDismissalConstants';
import dismissCollege from '../../collegedismissal/util/CollegeDismissalUtil';

interface CollegeRecommendationWamcGroup {
	wamcChanceGroupId: WamcChanceGroupId;
	wamcChanceGroupDescription: string;
	wamcIneligibleReason: WamcIneligibleReason;
}

export interface CollegeRecommendation extends CollegeRecommendationResult {
	score: number;
	wamcGroup: CollegeRecommendationWamcGroup;
}

const sortRecommendations = R.sort<CollegeRecommendation>(R.descend(R.prop('score')));

const emptyRecommendedCollegesByWamc = {
	[WamcChanceGroupId.LIKELY]: [],
	[WamcChanceGroupId.TARGET]: [],
	[WamcChanceGroupId.REACH]: [],
};

const filterNullWamcGroups = R.complement(R.pathEq(['wamcGroup', 'wamcChanceGroupId'], null));

type CreateWamcCollegesMap = (
	recommendedColleges: CollegeRecommendation[]
) => RecommendedCollegesByWamcGroup;

export const createWamcCollegesMap = (R.unless(
	R.isNil,
	R.pipe(
		R.filter<CollegeRecommendation, 'array'>(filterNullWamcGroups),
		R.groupBy<CollegeRecommendation>(R.path(['wamcGroup', 'wamcChanceGroupId'])),
		R.mergeRight<RecommendedCollegesByWamcGroup>(emptyRecommendedCollegesByWamc)
	)
) as unknown) as CreateWamcCollegesMap;

type GenerateHadRecommendations = (
	recommendedCollegesByWamcGroup: RecommendedCollegesByWamcGroup
) => HadRecommendationsByWamcGroup;

export const createHadRecommendationsMap = R.mapObjIndexed(
	(collegeRecommendation: CollegeRecommendationResult[]) =>
		!!(collegeRecommendation && collegeRecommendation.length)
) as GenerateHadRecommendations;

const createListFilter = (listToCompare: StudentCollegeConnection[]) => (
	recommendedCollege: CollegeRecommendation
) => R.none(R.propEq('collegeId', R.prop('collegeId', recommendedCollege)), listToCompare);

export const createFilterRecommendations = (collegeList: StudentCollegeConnection[]) =>
	R.unless<CollegeRecommendation[], CollegeRecommendation[]>(
		R.isNil,
		R.filter(createListFilter(collegeList))
	);

type CollegeRecommendationResponse = WebResponse<FormKeyedData, CollegeRecommendation[]>;

export const createRefreshCollegeRecommendations = (
	setCollegeRecommendationsData: (collegeRecommendations: CollegeRecommendation[]) => void,
	failAuth: () => void,
	onError: () => void
) => async () => {
	try {
		const { data } = await request<CollegeRecommendationResponse>({
			url: getEndpoint('/college-recommendation/v1/student', [{ name: 'limit', value: 20 }]),
			method: RequestMethod.GET,
			withCredentials: true,
			headers: [JsonContentTypeHeader],
		});

		if (data && data.response) {
			setCollegeRecommendationsData(data.response);
		} else {
			onError();
		}
	} catch (err) {
		if (err.response && (err.response.statusCode === 401 || err.response.statusCode === 403)) {
			failAuth();
		}

		onError();
	}
};

export const createDismissRecommendation = (
	clearRecommendation: (id: number) => void,
	failAuth: () => void,
	onError: () => void
) => async (id: number) => {
	clearRecommendation(id);

	await dismissCollege({
			collegeDismissalType: DismissalType.GENERAL,
			collegeId: id,
		},
		onError,
		failAuth)
};

interface CollegeRecommendationState {
	recommendedColleges: CollegeRecommendation[];
	recommendedCollegesByWamcGroup: RecommendedCollegesByWamcGroup;
	hadRecommendations: boolean;
	hadRecommendationsByWamcGroup: HadRecommendationsByWamcGroup;
}

const defaultCollegeRecommendationState: CollegeRecommendationState = {
	recommendedColleges: undefined,
	recommendedCollegesByWamcGroup: {
		[WamcChanceGroupId.LIKELY]: undefined,
		[WamcChanceGroupId.TARGET]: undefined,
		[WamcChanceGroupId.REACH]: undefined,
	},
	hadRecommendations: false,
	hadRecommendationsByWamcGroup: {
		[WamcChanceGroupId.LIKELY]: false,
		[WamcChanceGroupId.TARGET]: false,
		[WamcChanceGroupId.REACH]: false,
	},
};

const CollegeRecomendationProvider: FC = ({ children }) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { list } = useContext(StudentCollegeListContext);

	const [collegeRecommendationState, setCollegeRecommendationState] = useState(
		defaultCollegeRecommendationState
	);

	const failAuth = useFailedAuth();

	const { current: onError } = useRef(() => {
		openSnackbar({
			snackbarType: SnackbarType.Error,
			message: ErrorMessages.unknown,
		});
	});

	const initializeCollegeRecommendationsData = (recommendations: CollegeRecommendation[]) => {
		const sortedRecommendations = sortRecommendations(recommendations);
		const recommendationsMap = createWamcCollegesMap(sortedRecommendations);

		setCollegeRecommendationState({
			recommendedColleges: sortedRecommendations,
			recommendedCollegesByWamcGroup: recommendationsMap,
			hadRecommendations: recommendations.length > 0,
			hadRecommendationsByWamcGroup: createHadRecommendationsMap(recommendationsMap),
		});
	};

	const refreshCollegeRecommendations = useMemo(
		() =>
			createRefreshCollegeRecommendations(initializeCollegeRecommendationsData, failAuth, onError),
		[failAuth, onError]
	);

	const { current: clearRecommendation } = useRef((collegeId: number) => {
		const getNewRecommendations = R.filter(R.complement(R.propEq('collegeId', collegeId)));

		setCollegeRecommendationState(prevState => {
			const newRecommendations = getNewRecommendations(prevState.recommendedColleges);

			return {
				...prevState,
				recommendedColleges: newRecommendations,
				recommendedCollegesByWamcGroup: createWamcCollegesMap(newRecommendations),
			};
		});
	});

	const dismissRecommendation = useMemo(
		() => createDismissRecommendation(clearRecommendation, failAuth, onError),
		[failAuth, onError, clearRecommendation]
	);

	const [filteredRecommendations, filteredRecommendationsByWamcGroup] = useMemo(() => {
		const getNewRecommendations = createFilterRecommendations(list);
		const newRecommendations = getNewRecommendations(
			collegeRecommendationState.recommendedColleges
		);
		return [newRecommendations, createWamcCollegesMap(newRecommendations)];
	}, [list, collegeRecommendationState.recommendedColleges]);

	useEffect(() => {
		refreshCollegeRecommendations();
	}, [refreshCollegeRecommendations]);

	const collegeRecommendationContextValue = useMemo(
		() => ({
			...collegeRecommendationState,
			recommendedColleges: filteredRecommendations,
			recommendedCollegesByWamcGroup: filteredRecommendationsByWamcGroup,
			refreshCollegeRecommendations,
			clearRecommendation,
			dismissRecommendation,
		}),
		[
			filteredRecommendations,
			filteredRecommendationsByWamcGroup,
			clearRecommendation,
			collegeRecommendationState,
			dismissRecommendation,
			refreshCollegeRecommendations,
		]
	);

	return (
		<CollegeRecommendationContext.Provider value={collegeRecommendationContextValue}>
			{children}
		</CollegeRecommendationContext.Provider>
	);
};

export default CollegeRecomendationProvider;

import React, { FC, useCallback } from 'react';
import { styled, theme } from '@cappex/theme';
import defaultCollegeHero from '@common/assets/college/default-college-hero.jpg';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Card,
	CardActionArea,
	CardMedia,
	Fab,
	Grid,
	GridProps,
	Typography,
} from '@material-ui/core';
import PreferenceChip from '@src/features/collegepreferences/components/PreferenceChip';
import { EllipsisTypography } from '@cappex/components';
import { AutomationNameDefault } from '../util/automation';
import useViewDependentLink, {
	ViewDependentLinkType,
} from '../util/studentContext/util/useViewDependentLink';
import { useHistory } from 'react-router-dom';
import HeartButton from './HeartButton';

const EMPTY_TEXT = 'Empty Text';

interface StyledGridProps extends GridProps {
	marginleft?: string;
	marginright?: string;
	margintop?: string;
	marginbottom?: string;
	height?: string;
}

const StyledGrid = styled(Grid)<StyledGridProps>`
	${props => (props.margintop ? `margin-top: ${props.margintop};` : ``)}
	${props => (props.marginbottom ? `margin-bottom: ${props.marginbottom};` : ``)}
	${props => (props.marginright ? `margin-right: ${props.marginright};` : ``)}
	${props => (props.marginleft ? `margin-left: ${props.marginleft};` : ``)}
	${props => (props.height ? `height: ${props.height};` : ``)}
`;

const OverflowGrid = styled(StyledGrid)`
	overflow-x: hidden;
`;

export const CollegeImageCard = styled(Card)`
	border-radius: 0.5rem 0.5rem 0 0;
	position: relative;
	max-height: 7.5rem;
`;

const CardMediaStyled = styled(CardMedia)`
	object-fit: cover;
	height: 100%;
` as typeof CardMedia;

const FabStyled = styled(Fab)`
	position: relative;
	background-color: ${props => props.theme.palette.common.white};
`;

const PreferenceChipStyled = styled(PreferenceChip)`
	border: 0px;
	background-color: ${props => props.theme.palette.grey[200]};
	opacity: 1 !important;
`;

const NoPreferenceChipStyled = styled(PreferenceChip)`
	border: 0px;
	background-color: ${props => props.theme.palette.common.white};
`;

const FabGrid = styled(Grid)`
	flex: none;
	padding: 0.5rem 0.5rem 1rem 0.5rem;
	margin: 0 0.5rem;
`;

const ShadowGrid = styled(Grid)`
	border-radius: 0.5rem;
	box-shadow: ${props => props.theme.shadows[3]};
`;

// Used to maintain proper spacing for when data is not found
const HiddenTypography = styled(Typography)`
	visibility: hidden;
`;

export type OutreachCarouselCardProps = {
	collegeId: number;
	collegeName: string;
	collegeLocation: string;
	collegeHeroImage?: string;
	preferences?: string[];
	dismissCollege: (collegeId: number) => void;
	saveCollege: (collegeId: number) => void;
};

const OutreachCarouselCard: FC<OutreachCarouselCardProps> = ({
	collegeId,
	collegeName,
	collegeLocation,
	collegeHeroImage,
	preferences,
	dismissCollege,
	saveCollege,
}) => {
	const { push } = useHistory();
	const profileLinkBase = useViewDependentLink(ViewDependentLinkType.COLLEGE_PROFILE);

	const dismissClicked = useCallback(() => {
		dismissCollege(collegeId);
	}, [dismissCollege, collegeId]);

	const saveClicked = useCallback(() => {
		saveCollege(collegeId);
	}, [saveCollege, collegeId]);

	return (
		<ShadowGrid
			container
			spacing={1}
			direction="column"
			data-qa={AutomationNameDefault.outreachCarouselCard}
		>
			<Grid>
				<CollegeImageCard>
					<CardActionArea
						disableRipple
						onClick={() => push(`/${profileLinkBase}/${collegeId}/${collegeName}`)}
					>
						<CardMediaStyled
							component="img"
							src={collegeHeroImage || defaultCollegeHero}
							data-qa={AutomationNameDefault.outreachCarouselCardHero}
						/>
					</CardActionArea>
				</CollegeImageCard>
			</Grid>
			<StyledGrid item height="5.625rem">
				<Grid container direction="column" spacing={1}>
					<StyledGrid item xs marginleft="1rem" marginbottom="-0.5rem" margintop="0.5rem">
						<EllipsisTypography
							lines={2}
							variant="body1"
							automationName={AutomationNameDefault.outreachCarouselCardSchoolName}
						>
							{collegeName}
						</EllipsisTypography>
					</StyledGrid>
					<StyledGrid item xs marginleft="1rem">
						{collegeLocation === '' ? (
							<HiddenTypography
								variant="caption"
								data-qa={AutomationNameDefault.outreachCarouselCardSchoolLocation}
							>
								{EMPTY_TEXT}
							</HiddenTypography>
						) : (
							<Typography
								variant="caption"
								color="textSecondary"
								data-qa={AutomationNameDefault.outreachCarouselCardSchoolLocation}
							>
								{collegeLocation}
							</Typography>
						)}
					</StyledGrid>
				</Grid>
			</StyledGrid>
			<StyledGrid item height="5.625rem" marginbottom="4.25rem" marginright=".75rem">
				<OverflowGrid container spacing={1} height="auto">
					<StyledGrid item marginleft=".75rem" marginbottom="0.25rem">
						{preferences[0] && (
							<PreferenceChipStyled
								disabled
								clickable={false}
								shouldFitContent
								label={preferences[0]}
								data-qa={AutomationNameDefault.outreachCarouselCardPreferenceOne}
							/>
						)}
					</StyledGrid>
					<Grid item>
						<StyledGrid item marginleft=".75rem" marginright=".75rem" marginbottom="0.25rem">
							{preferences[1] ? (
								<PreferenceChipStyled
									disabled
									clickable={false}
									shouldFitContent
									label={preferences[1]}
									data-qa={AutomationNameDefault.outreachCarouselCardPreferenceTwo}
								/>
							) : (
								<NoPreferenceChipStyled disabled clickable={false} shouldFitContent />
							)}
						</StyledGrid>
					</Grid>
				</OverflowGrid>
				<OverflowGrid container spacing={1} height="auto">
					<StyledGrid item marginleft=".75rem" marginright=".75rem">
						{preferences[2] ? (
							<PreferenceChipStyled
								disabled
								clickable={false}
								shouldFitContent
								label={preferences[2]}
								data-qa={AutomationNameDefault.outreachCarouselCardPreferenceThree}
							/>
						) : (
							<NoPreferenceChipStyled disabled clickable={false} shouldFitContent />
						)}
					</StyledGrid>
					<Grid item>
						<StyledGrid item marginleft=".75rem" marginright=".75rem">
							{preferences[3] ? (
								<PreferenceChipStyled
									disabled
									clickable={false}
									shouldFitContent
									label={preferences[3]}
									data-qa={AutomationNameDefault.outreachCarouselCardPreferenceFour}
								/>
							) : (
								<NoPreferenceChipStyled disabled clickable={false} shouldFitContent />
							)}
						</StyledGrid>
					</Grid>
				</OverflowGrid>
			</StyledGrid>
			<Grid item xs>
				<Grid container spacing={1} justifyContent="center">
					<FabGrid>
						<FabStyled
							size="medium"
							data-qa={AutomationNameDefault.outreachCarouselCardDismiss}
							onClick={dismissClicked}
						>
							<FontAwesomeIcon fontSize="1.5rem" icon={faTimes} color={theme.palette.ink.light} />
						</FabStyled>
					</FabGrid>
					<FabGrid>
						<HeartButton
							size="medium"
							data-qa={AutomationNameDefault.outreachCarouselCardSave}
							onClick={saveClicked}
							colorVariant="coral"
						/>
					</FabGrid>
				</Grid>
			</Grid>
		</ShadowGrid>
	);
};

export default OutreachCarouselCard;

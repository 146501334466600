import CssHideWhen from '@src/common/components/CssHideWhen';
import * as R from 'ramda';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import {
	FlowComponent,
	ReconfigureArgs,
	RoutingFlowComponent,
	Step,
	StepConfiguration,
	stepToComponentMapping,
} from '..';
import useDerivedStateFromPropsWithMap from '../../hooks/useDerivedStateFromPropsWithMap';
import { includedInStepCount, name } from '../constants';
import { getStepCountFromStepArray } from './StepCounter';
import evaluatePredicate from '../../form/evaluatePredicate';
import { FormContext } from '../../validation/form';
import { DataFlowType, FormNames, ListFormNames } from '@cappex/constants';
import HiddenInput from '@src/common/components/HiddenInput';
import splitIdIntoParts from '../../../../features/dataflow/util/splitIdIntoParts';
import CustomMapping from '@common/components/CustomMapping';

const RouteBasedOneStepAtATimeFactory = <
	T extends Step<any, any & { [name]: string; [includedInStepCount]: boolean; pseudo?: boolean }>
>(
	stepToComponent: stepToComponentMapping<T>
): FlowComponent<T> => {
	const RouteBasedOneStepAtATimeFlow: RoutingFlowComponent<T> = ({
		initialId,
		initialConfig,
		initialDataFlowType,
	}) => {
		const initialConsentsValue = useMemo(() => [], []);

		const getStepName = (givenConfig: StepConfiguration<T>, step: number) =>
			givenConfig.steps[step].data[name];

		const history = useHistory();
		const location = useLocation();
		const match = useRouteMatch();
		const { formState } = useContext(FormContext);

		const [config, setConfig] = useDerivedStateFromPropsWithMap(initialConfig);
		const [id, setId] = useState(initialId);
		const { dataFlowCode, dataFlowVersion } = useMemo(() => splitIdIntoParts(id), [id]);
		const [dataFlowType, setDataFlowType] = useState<DataFlowType>(initialDataFlowType);

		const toRelpath = useCallback((stepName: string) => `${dataFlowVersion}/${stepName}`, [
			dataFlowVersion,
		]);

		const getStepPredicate = useCallback(
			(step: number) => R.path([step, 'data', 'predicate'], config.steps),
			[config.steps]
		);

		const limitStep = useMemo(() => R.compose(R.max(0), R.min(config.steps.length - 1)), [
			config.steps,
		]);
		const [currentStepIndex, setStepIndex] = useState<number>(
			R.pipe(
				R.map((step: Step<any, { [name]: string }>) => toRelpath(step.data[name])),
				R.findIndex(stepPath => location.pathname.match(stepPath) !== null),
				// if step is not rendered because of predicate, render first step instead
				R.ifElse(
					step =>
						!getStepPredicate(step) ||
						evaluatePredicate(config.steps[step].data.predicate)(formState),
					index => index,
					() => 0
				),
				limitStep
			)(config.steps)
		);

		// Listen to the History to stay in sync with browser forward/back.
		useEffect(() => {
			const unlisten = history.listen((newLocation, action) => {
				if (action === 'POP' || action === 'REPLACE') {
					const newStep = R.pipe(
						R.map((step: Step<any, { [name]: string }>) => toRelpath(step.data[name])),
						R.findIndex(stepPath => newLocation.pathname.match(stepPath) !== null),
						limitStep
					)(config.steps);

					const canRenderStep =
						!getStepPredicate(newStep) || evaluatePredicate(getStepPredicate(newStep))(formState);

					if (canRenderStep) {
						const stepChanged = newStep !== currentStepIndex; // prevents state update warning
						stepChanged && setStepIndex(newStep);
					} else {
						history.goBack();
					}
				}
			});
			return unlisten;
		}, [config, history, limitStep, getStepPredicate, formState, toRelpath, currentStepIndex]);

		const { current: currentCountedStep, total: totalCountedSteps } = getStepCountFromStepArray(
			config.steps,
			currentStepIndex,
			formState
		);

		// Shove Current+Total Steps into the data
		config.steps[currentStepIndex].data = {
			...config.steps[currentStepIndex].data,
			currentStep: currentCountedStep,
			totalSteps: totalCountedSteps,
		};

		const changeStepWithReconfigure = useCallback(
			(by: number) => (props: ReconfigureArgs<StepConfiguration<T>>, replace?: boolean) => {
				let newStep = limitStep(currentStepIndex + by);
				const newDataFlowVersion = props?.newId
					? splitIdIntoParts(props.newId).dataFlowVersion
					: dataFlowVersion;

				const newDataFlowCode = props?.newId
					? splitIdIntoParts(props.newId).dataFlowCode
					: dataFlowCode;

				// skip steps where predicate exists and evaluates to false
				while (
					getStepPredicate(newStep) &&
					!evaluatePredicate(getStepPredicate(newStep))(formState)
				) {
					newStep += by;
				}
				if (props?.newId) {
					setId(props.newId);
				}

				if (props?.dataFlowType) {
					setDataFlowType(props.dataFlowType);
				}

				if (props && props.newConfig) {
					setConfig(props.newConfig);
					setStepIndex(props.newStep || newStep);
				} else {
					setStepIndex(newStep);
				}

				const getRouteFromConfig = (givenConfig: StepConfiguration<T>) =>
					`/register/${newDataFlowCode}/${newDataFlowVersion}/${getStepName(givenConfig, newStep)}${
						window.location.search
					}`;

				const route = getRouteFromConfig(props && props.newConfig ? props.newConfig : config);

				if (replace) {
					history.replace(route);
				} else {
					history.push(route);
				}

				window.scrollTo(0, 0);
			},
			[
				dataFlowVersion,
				dataFlowCode,
				config,
				currentStepIndex,
				history,
				limitStep,
				setConfig,
				formState,
				getStepPredicate,
			],
		);

		const complete = useMemo(() => changeStepWithReconfigure(1), [changeStepWithReconfigure]);
		const reverse = useMemo(() => changeStepWithReconfigure(-1), [changeStepWithReconfigure]);

		const BoundComponents = useMemo(
			() =>
				config.steps.map((step: T, idx: number) => {
					const Component = stepToComponent(step);
					return (
						(!step.data.pseudo || idx === currentStepIndex) && (
							<CssHideWhen
								key={`${step.key}-${step.sortOrder}-hide`}
								when={idx !== currentStepIndex}
							>
								<Component
									key={`${step.key}-${step.sortOrder}`}
									data={step.data}
									active={idx === currentStepIndex}
									complete={complete}
									reverse={reverse}
									customLogoUrl={config.customLogoUrl}
									redirectIfAccountExists={config.redirectIfAccountExists}
									dataFlowType={dataFlowType}
									saveResults={config.saveResults}
								/>
							</CssHideWhen>
						)
					);
				}),
			[
				config.steps,
				config.customLogoUrl,
				config.saveResults,
				config.redirectIfAccountExists,
				currentStepIndex,
				complete,
				reverse,
				dataFlowType,
			],
		);
		const BoundRoutes = config.steps.map((step: T) => (
			<Route
				exact
				path={`${match.path}/${toRelpath(step.data[name])}`}
				key={`${step.key}-${step.sortOrder}-route`}
			/>
		));

		return (
			<>
				<HiddenInput
					name={FormNames.dataFlowCode}
					id="dataFlowCode"
					initialValue={dataFlowCode}
					automationName="hidden-input"
				/>
				<HiddenInput
					name={FormNames.dataFlowVersion}
					id="dataFlowVersion"
					initialValue={dataFlowVersion}
					automationName="hidden-input"
				/>
				<HiddenInput
					name={ListFormNames.consents}
					id="consents"
					initialValue={initialConsentsValue}
					automationName="hidden-input"
				/>
				<CustomMapping config={config}/>
				{BoundComponents}
				<Switch>{BoundRoutes}</Switch>
			</>
		);
	};
	return RouteBasedOneStepAtATimeFlow;
};
export default RouteBasedOneStepAtATimeFactory;

import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import { useLocation } from 'react-router-dom';
import LoadingComponent from '@src/common/components/LoadingComponent';
import { SnackbarContext, SnackbarType } from '@src/common/components/SnackbarManager';
import { ModalContext, QueueAction } from '@src/common/util/steps/components/ModalStepFlow';
import OfferInterstitialModal from '@src/features/offer/containers/OfferInterstitialModal';
import request, {
	ErrorMessages,
	JsonAcceptHeader,
	JsonContentTypeHeader,
	RequestMethod,
} from '@cappex/request';
import useCampaignOffers from '@util/hooks/useCampaignOffers';
import getEndpoint from '@util/request';
import { getRegistrationInterstitialPlacementByPathname } from '@src/features/offer/util/offerUtil';
import { FormContext } from '@src/common/util/validation/form';
import { JitEvent } from '@src/common/util/jit/constants';
import { DataFlowStepComponent } from '../../constants/types';
import { StepContainerProps } from '@src/common/util/steps';
import { QuizContext, Result } from '@util/quiz';
import { DataFlowType, FormNames } from '@cappex/constants';
import EventBus, { EventTypes } from '@src/features/app/util/EventBus';

const OFFER_INTERSTITIAL_MODAL_KEY = 'offer-interstitial-modal';

const useFinalizeDataFlow = (
	requestData: {
		dataFlowCode: any;
		dataFlowVersion: any;
	},
	onSuccess: () => void,
	setError: (isError: boolean) => void,
	getCurrentResult: () => Result,
	saveResults: boolean
) => {
	const requestBody = { completeDataFlow: true, ...requestData };
	const quizIdentifier = `${requestData.dataFlowCode}.${requestData.dataFlowVersion}`;

	let newForm = requestBody;

	if (saveResults && getCurrentResult()) {
		newForm = R.assocPath(
			['student', FormNames.studentQuizResultForm, FormNames.quizResults],
			{ [quizIdentifier]: getCurrentResult().header },
			requestBody
		);
	}

	return useCallback(async () => {
		const res = await request<any>({
			url: getEndpoint('/data-flow/v1/submit-data'),
			method: RequestMethod.POST,
			data: newForm,
			withCredentials: true,
			headers: [JsonAcceptHeader, JsonContentTypeHeader],
		});
		if (res?.data?.meta?.success) {
			onSuccess();
		} else {
			setError(true);
		}
	}, [onSuccess, setError, newForm]);
};

const useDataFlowFormValues = () => {
	const { getValue } = useContext(FormContext);

	const { dataFlowCode } = getValue('dataFlowCode') as {
		dataFlowCode: string;
	};
	const { dataFlowVersion } = getValue('dataFlowVersion') as {
		dataFlowVersion: string;
	};
	const { studentTypeId } = getValue('studentTypeId') as { studentTypeId: string };
	const { studentColleges } = getValue('studentColleges') as { studentColleges: any[] };

	const { eventId } = getValue('eventId') as { eventId: string };

	const value = useMemo(
		() => ({ dataFlowCode, dataFlowVersion, studentTypeId, studentColleges, eventId }),
		[dataFlowCode, dataFlowVersion, studentTypeId, studentColleges, eventId]
	);

	return value;
};

const FinalizeStep: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	complete,
	data: { skipLoading },
	dataFlowType,
	saveResults
}) => {
	const { pathname } = useLocation();
	const { queueModal } = useContext(ModalContext);
	const { openSnackbar } = useContext(SnackbarContext);
	const { getCurrentResult } = useContext(QuizContext);
	const [isError, setIsError] = useState(false);
	const [calledFinalize, setCalledFinalize] = useState(false);
	const formValues = useDataFlowFormValues();
	const [mounted, setMounted] = useState(false);

	const onCampaignOfferError = useCallback(() => {
		openSnackbar({
			snackbarType: SnackbarType.Error,
			message: ErrorMessages.unknown,
		});
	}, [openSnackbar]);

	const interstitialPlacement = useMemo(
		() => getRegistrationInterstitialPlacementByPathname(pathname),
		[pathname]
	);

	const [campaignOffers, isCampaignOffersReady] = useCampaignOffers(
		interstitialPlacement,
		onCampaignOfferError
	);

	const queueOfferInterstitialModal = useCallback(() => {
		if (!R.isNil(campaignOffers) && !R.isEmpty(campaignOffers)) {
			queueModal(QueueAction.PREPEND, OFFER_INTERSTITIAL_MODAL_KEY, OfferInterstitialModal, {
				placement: interstitialPlacement,
				campaignOffers,
			});
		}
	}, [queueModal, campaignOffers, interstitialPlacement]);

	const onSuccessfulFinalize = useCallback(() => {
		const collegeList = formValues?.studentColleges;

		if (collegeList && collegeList.length > 0) {
			EventBus.emit(EventTypes.JIT_CHECK, {
				eventType: JitEvent.COLLEGE_INQUIRY,
				collegeIds: collegeList.map(item => item.collegeId),
			});
		}

		if (dataFlowType === DataFlowType.REGISTRATION) {
			EventBus.emit(EventTypes.JIT_CHECK, {
				eventType: JitEvent.REGISTRATION_COMPLETION,
				dataFlowId: `${formValues.dataFlowCode}.${formValues.dataFlowVersion}`,
			});

			queueOfferInterstitialModal();
		}

		complete();
	}, [
		queueOfferInterstitialModal,
		complete,
		formValues.dataFlowCode,
		formValues.dataFlowVersion,
		formValues.studentColleges,
		dataFlowType,
	]);

	const finalize = useFinalizeDataFlow(
		formValues,
		onSuccessfulFinalize,
		setIsError,
		getCurrentResult,
		saveResults
	);

	// Once we've got everything lined up, try to Finalize automatically
	useEffect(() => {
		if (isCampaignOffersReady && !isError && !skipLoading && !calledFinalize) {
			setCalledFinalize(true);
			finalize();
		}
	}, [isError, isCampaignOffersReady, finalize, skipLoading, calledFinalize]);

	useEffect(() => {
		if (mounted) return;
		setMounted(true);
		if (skipLoading) {
			finalize();
			complete();
		}
	}, [finalize, complete, skipLoading, mounted]);

	return <LoadingComponent error={isError} retry={finalize} />;
};

export default FinalizeStep;

import * as R from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import LoadingComponent from '@src/common/components/LoadingComponent';
import SingleCollegeInput from '@src/common/components/SingleCollegeInput';
import useUrlParam from '@src/common/util/hooks/useUrlParam';
import request, { JsonAcceptHeader, JsonContentTypeHeader, RequestMethod } from '@cappex/request';
import getEndpoint from '@util/request';
import { StepContainerProps } from '@src/common/util/steps';
import {
	StudentCollegeListLocationTrackingValue,
	StudentCollegeListTypeTrackingValue,
} from '@src/common/util/studentcollege';
import withStyleOptions from '@src/common/util/style/styleOptions';
import { ModalContext, QueueAction } from '@src/common/util/steps/components/ModalStepFlow';
import { DataFlowStepComponent } from '../../constants/types';
import DataFlowContext from '../../util/DataFlowContext';
import DataFlowContainer from '../DataFlowContainer';
import ChancesModal from '../ChancesModal';
import LegalTextAndLinks from '@common/components/LegalTextAndLinks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@cappex/theme';

const PaddedBox = styled(Box)`
	padding-left: 1rem;
	padding-right: 1rem;
`;

const CHANCES_LANDING_MODAL_KEY = 'chances-landing-modal';

const RegisterButton = withStyleOptions(Button);

const FlexStepChancesLandingPage: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	data: {
		topMedia,
		backgroundMedia,
		variant,
		leftMedia,
		rightMedia,
		hideLeftMediaImageMobile,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
		buttonConfig,
		legalTextConfig,
	},
	complete,
	active,
	customLogoUrl,
}) => {
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const { queueModal } = useContext(ModalContext);

	const [isButtonDisabled, setButtonDisabled] = useState(false);
	const [isCollegePending, setIsCollegePending] = useState(true);
	const [isCollegeValid, setIsCollegeValid] = useState(false);
	const [collegeName, setCollegeName] = useState('');
	const [collegeId, setCollegeId] = useState();

	const urlCollegeId = useUrlParam('collegeId');

	const onCollegeSelected = (collegeObject: any) => {
		setCollegeName(collegeObject.label);
		setCollegeId(collegeObject.value);
		setButtonDisabled(false);
	};

	const onCollegeIdAdded = collegeIdFromInput => {
		queueModal(QueueAction.APPEND, CHANCES_LANDING_MODAL_KEY, ChancesModal, {
			name: collegeName,
			id: collegeIdFromInput,
		});
		complete();
	};

	const onClick = () => {
		setPreHook(() => () => {
			setButtonDisabled(false);
		});

		setPostHook(() => responseData => {
			if (responseData.meta.success) {
				queueModal(QueueAction.PREPEND, CHANCES_LANDING_MODAL_KEY, ChancesModal, {
					name: collegeName,
					id: collegeId,
				});
				complete();
				setButtonDisabled(false);
			}
		});

		setErrorHook(() => () => {
			setButtonDisabled(false);
		});
		return true;
	};

	useEffect(() => {
		if (active && isCollegePending) {
			if (urlCollegeId !== null && urlCollegeId.length > 0) {
				request<any>({
					url: getEndpoint(`/colleges/v1/public/retrieve/${urlCollegeId}`),
					method: RequestMethod.GET,
					headers: [JsonAcceptHeader, JsonContentTypeHeader],
					withCredentials: true,
				})
					.then(res => {
						const { data } = res;
						const { college } = R.values(data.response)[0];

						if (data.meta.success && !R.isNil(data.response) && !R.isEmpty(college.name)) {
							setCollegeName(college.name);
							setCollegeId(college.id);
							setIsCollegeValid(true);
							setIsCollegePending(false);
						} else {
							setIsCollegePending(false);
						}
					})
					.catch(() => {
						setIsCollegePending(false);
					});
			} else {
				setIsCollegePending(false);
			}
		}
	}, [urlCollegeId, isCollegePending, active, complete, queueModal]);

	return (
		<>
			{(isCollegePending || isCollegeValid) && <LoadingComponent />}
			{!isCollegePending && isCollegeValid && (
				<SingleCollegeInput
					onSelect={onCollegeSelected}
					locationTrackingValue={StudentCollegeListLocationTrackingValue.CHANCES_TO_GET_IN}
					typeTrackingValue={StudentCollegeListTypeTrackingValue.EXPLORE}
					collegeId={urlCollegeId}
					notifyOnCollegeIdAddedToForm={onCollegeIdAdded}
					multiFormInit
				/>
			)}
			{!isCollegePending && !isCollegeValid && (
				<DataFlowContainer
					topMedia={topMedia}
					backgroundMedia={backgroundMedia}
					variant={variant}
					leftMedia={leftMedia}
					rightMedia={rightMedia}
					hideLeftMediaImageMobile={hideLeftMediaImageMobile}
					showLeftTextMediaMobile={showLeftTextMediaMobile}
					showRightTextMedia={showRightTextMedia}
					currentStep={currentStep}
					totalSteps={totalSteps}
					customLogoUrl={customLogoUrl}
				>
					<Grid container justifyContent="center" spacing={4}>
						<Grid item xs={12}>
							<SingleCollegeInput
								onSelect={onCollegeSelected}
								locationTrackingValue={StudentCollegeListLocationTrackingValue.CHANCES_TO_GET_IN}
								typeTrackingValue={StudentCollegeListTypeTrackingValue.SEARCH_BAR}
								multiFormInit
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<RegisterButton
								data-qa="flex-create-account-button"
								$noneTextTransform
								$boldFontWeight
								type="submit"
								variant="contained"
								color="primary"
								fullWidth
								disabled={isButtonDisabled}
								onClick={onClick}
							>
								{buttonConfig?.text ?? 'Next'}
								{buttonConfig?.icon && (
									<PaddedBox>
										<FontAwesomeIcon icon={['fal', buttonConfig.icon]} />
									</PaddedBox>
								)}
							</RegisterButton>
						</Grid>
					</Grid>
					{legalTextConfig && <LegalTextAndLinks configs={legalTextConfig} />}
				</DataFlowContainer>
			)}
		</>
	);
};

export default FlexStepChancesLandingPage;

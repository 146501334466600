import * as R from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { styled } from '@cappex/theme';
import AddressBlock from '@common/components/AddressBlock';
import BirthDateInput from '@common/components/BirthDateInput';
import GenderSelect from '@common/components/GenderSelect';
import GradDateInput from '@common/components/GradDateInput';
import MajorFormInput from '@common/components/MajorFormInput';
import NameForm from '@common/components/NameForm';
import PhoneNumberInput from '@common/components/PhoneNumberInput';
import StartTermInput from '@common/components/StartTermInput';
import UnweightedGpaInput from '@common/components/UnweightedGpaInput';
import checkLockout from '@util/lockout';
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { SubForm, TopLevelForm } from '@src/common/components/BaseValidationForm';
import HiddenInput from '@src/common/components/HiddenInput';
import LegalTextAndLinks from '@src/common/components/LegalTextAndLinks';
import MultiCollegeInput from '@src/common/components/MultiCollegeInput';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import { FormNames, ListFormNames } from '@cappex/constants';
import AuthContext, { AuthenticState } from '@src/common/util/auth';
import useUserData from '@src/common/util/hooks/useUserData';
import getEndpoint from '@util/request';
import request, {
	checkForFormError,
	FORM_NAME,
	getFormErrors,
	JsonAcceptHeader,
	JsonContentTypeHeader,
	RequestMethod,
} from '@cappex/request';
import { StepContainerProps } from '@src/common/util/steps';
import {
	getStudentType,
	parseStudent,
	Student,
	StudentType,
} from '@src/common/util/student/studentDataUtil';
import {
	StudentCollegeListLocationTrackingValue,
	StudentCollegeListTypeTrackingValue,
} from '@src/common/util/studentcollege';
import withStyleOptions from '@src/common/util/style/styleOptions';
import { FormContext, FormContextValue, FormFields } from '@util/validation/form';
import { DataFlowStepComponent } from '../../constants/types';
import DataFlowContext from '../../util/DataFlowContext';
import DataFlowContainer from '../DataFlowContainer';
import CurrentCollegeInput from '@src/common/components/CurrentCollegeInput';
import YesNoSelectorInput, { YesNoSelection } from '@src/common/components/YesNoSelectorInput';
import EthnicityCheckboxList from '@src/common/components/EthnicityCheckboxList';
import StudentTypeSelect, {
	StudentTypeSelectProps,
} from '@src/common/components/StudentTypeSelect';
import FirstGenerationStudent from '@common/components/FirstGenerationStudent';
import TransferTermInput from '@src/common/components/TransferTermInput';

interface CreateAccountFormFields extends FormFields {
	[FormNames.email]?: string;
	[FormNames.password]?: string;
	[FormNames.uuid1]?: string;
	[FormNames.uuid2]?: string;
}

const EMPTY_FUNCTION = () => {};

const RegisterButton = withStyleOptions(Button);

const GutterBottomTypography = styled(Typography)`
	margin-bottom: 1rem;
`;

const SearchWrapper = styled.div`
	margin-bottom: 0.5rem;
`;

function handleFailure(
	json: any,
	setButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>,
	setFormErrors: FormContextValue['setFormErrors'],
	openErrorSnack: (formError: string) => void
) {
	const lockedOut = checkLockout(json);
	if (!lockedOut) {
		setButtonDisabled(false);
		const formErrors: CreateAccountFormFields = getFormErrors(json);
		setFormErrors(formErrors);
		if (checkForFormError(formErrors)) {
			openErrorSnack(formErrors[FORM_NAME]);
		}
	}
}

const STUDENT_TYPE_OPTIONS = [
	{ displayValue: 'High school', value: StudentType.HIGH_SCHOOL },
	{ displayValue: 'College', value: StudentType.COLLEGE },
];

const StudentDataInputs = ({ active, userData }) => (
	<>
		<SubForm name="studentHighSchoolDataForm">
			<Grid item>
				<GradDateInput
					required={active}
					defaultValue={{
						year: userData.highSchoolGradYear,
						month: userData.highSchoolGradMonth,
					}}
					label="High School Graduation Date"
				/>
			</Grid>
			<HiddenInput name={FormNames.requireHighSchoolId} initialValue="false" />
		</SubForm>
		<SubForm name="studentCollegeDataForm">
			<Grid item>
				<StartTermInput
					required={active}
					defaultValue={{
						year: userData.collegeStartYear,
						season: userData.collegeStartTermId,
					}}
					label="Expected College Start Term"
				/>
			</Grid>
		</SubForm>
		<SubForm name="studentHighSchoolDataForm">
			<Grid item xs={12}>
				<Typography variant="h6">GPA</Typography>
				<UnweightedGpaInput
					fullWidth
					required={active}
					name={FormNames.gpaUnweighted}
					label="Unweighted GPA"
					helperText="Up to 4.00"
				/>
			</Grid>
		</SubForm>
	</>
);

const StudentCollegeAndPreferencesInputs = () => (
	<>
		<Grid item xs={12}>
			<GutterBottomTypography variant="h6">
				Add up to 10 colleges you&apos;re considering
			</GutterBottomTypography>
			<TopLevelForm>
				<SearchWrapper>
					<MultiCollegeInput
						zindexInner={6}
						typeTrackingValue={StudentCollegeListTypeTrackingValue.SEARCH_BAR}
						locationTrackingValue={StudentCollegeListLocationTrackingValue.REGISTRATION_PATH}
					/>
				</SearchWrapper>
			</TopLevelForm>
		</Grid>
		<SubForm name="collegePreferences">
			<Grid item xs={12}>
				<Typography variant="h6">Majors</Typography>
				<MajorFormInput subText="Select up to 5" name={ListFormNames.majorCipCodes} />
			</Grid>
		</SubForm>
	</>
);

const CollegeStudentDataComponents = ({ userData, active }) => {
	const { getValue } = useContext(FormContext);
	const transferStudent = String(
		getValue(FormNames.openToTransfer)[FormNames.openToTransfer]
	) as YesNoSelection;

	return (
		<>
			<SubForm name="studentHighSchoolDataForm">
				<Grid item>
					<GradDateInput
						required={active}
						defaultValue={{
							year: userData.highSchoolGradYear,
							month: userData.highSchoolGradMonth,
						}}
						label="High School Graduation Date"
					/>
				</Grid>
				<HiddenInput name={FormNames.requireHighSchoolId} initialValue="false" />
			</SubForm>
			<SubForm name="studentCollegeDataForm">
				<Grid item xs={12}>
					<CurrentCollegeInput label="Current College" placeholder="Find My College" flat />
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Typography variant="h6">College GPA</Typography>
						</Grid>
						<Grid item xs={12}>
							<UnweightedGpaInput
								name={FormNames.collegeGpa}
								label="College GPA"
								initialValue={userData.collegeGpa}
								fullWidth
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<YesNoSelectorInput
						name={FormNames.openToTransfer}
						title="Are you considering transferring colleges?"
						required={active}
						defaultValue={YesNoSelection.NO_ANSWER}
					/>
				</Grid>
				{transferStudent === YesNoSelection.YES && (
					<>
						<Grid item xs={12}>
							<TransferTermInput label="Expected Transfer Start Term" />
						</Grid>

						<Grid item xs={12}>
							<SubForm distinct name="collegePreferences">
								<MajorFormInput
									label="Intended Major"
									subText="Select up to 5"
									name={ListFormNames.majorCipCodes}
								/>
							</SubForm>
						</Grid>
						<Grid item xs={12}>
							<GutterBottomTypography variant="h6">
								Which college(s) are you considering?
							</GutterBottomTypography>
							<TopLevelForm>
								<SearchWrapper>
									<MultiCollegeInput
										zindexInner={1}
										typeTrackingValue={StudentCollegeListTypeTrackingValue.SEARCH_BAR}
										locationTrackingValue={
											StudentCollegeListLocationTrackingValue.REGISTRATION_PATH
										}
									/>
								</SearchWrapper>
							</TopLevelForm>
						</Grid>
					</>
				)}
			</SubForm>
		</>
	);
};

const SCHOLARSHIP_CHIP_COLUMNS: StudentTypeSelectProps['chipColumns'] = {};

const ScholarshipInfoPage: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	data: {
		leftMedia,
		rightMedia,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
		legalTextConfig,
	},
	active,
	complete,
	customLogoUrl,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { setFormErrors, getValue } = useContext(FormContext);
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const { isAuthentic } = useContext(AuthContext);
	const [userInfo] = useUserData(EMPTY_FUNCTION, active, true);

	const [isButtonDisabled, setButtonDisabled] = useState(false);
	const [userData, setUserData] = useState<Partial<Student>>({});

	const [studentType, setStudentType] = useState(StudentType.HIGH_SCHOOL);
	useEffect(() => {
		const newStudentType =
			getStudentType((getValue(FormNames.studentTypeId) as FormFields)?.studentTypeId) ||
			userData.studentTypeId;
		if (newStudentType && newStudentType !== studentType) {
			setStudentType(newStudentType);
		}
	}, [studentType, getValue, userData]);

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	// Get user info to pre-populate
	useEffect(() => {
		if (R.isNil(userInfo.roleId)) {
			return;
		}

		request<any>({
			url: getEndpoint(`/student/v2/retrieve`),
			method: RequestMethod.GET,
			withCredentials: true,
			headers: [JsonAcceptHeader, JsonContentTypeHeader],
		})
			.then(res => res.data.response)
			.then(data => {
				setUserData(parseStudent(data));
			});
	}, [userInfo]);

	const onClick = () => {
		setPreHook(() => () => {
			setButtonDisabled(true);
		});

		setPostHook(() => responseData => {
			if (responseData.meta.success) {
				complete();
				setButtonDisabled(false);
			} else {
				handleFailure(responseData, setButtonDisabled, setFormErrors, openErrorSnack);
			}
		});

		setErrorHook(() => () => {
			setButtonDisabled(false);
			handleFailure(
				{
					meta: { success: false },
				},
				setButtonDisabled,
				setFormErrors,
				openErrorSnack
			);
		});
		return true;
	};

	if (
		(isAuthentic === AuthenticState.Authentic || isAuthentic === AuthenticState.Unknown) &&
		active
	) {
		complete();
	}

	return (
		<DataFlowContainer
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<Grid container justifyContent="center" spacing={4}>
				<Grid item xs={12}>
					<StudentTypeSelect
						title="I'm Currently In"
						defaultStudentTypeId={userData.studentTypeId || StudentType.HIGH_SCHOOL}
						options={STUDENT_TYPE_OPTIONS}
						isDefault={!!userData.studentTypeId}
						chipColumns={SCHOLARSHIP_CHIP_COLUMNS}
					/>
				</Grid>
				<SubForm name="student">
					<SubForm name="studentDataForm">
						<Grid item xs={12}>
							<NameForm
								fullWidth
								required={active}
								label="Name"
								firstNameLabel="First Name"
								lastNameLabel="Last Name"
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6">Gender</Typography>
							<GenderSelect name={FormNames.genderId} label="Gender (Optional)" />
						</Grid>
					</SubForm>
					<SubForm name="studentDataForm">
						<Grid item xs={12}>
							<BirthDateInput
								required={active}
								id="registration__birth_date"
								label="Date of Birth"
							/>
						</Grid>
					</SubForm>
					{studentType === StudentType.HIGH_SCHOOL && (
						<SubForm name="studentCollegeDataForm">
							<Grid item xs={12}>
								<FirstGenerationStudent />
							</Grid>
						</SubForm>
					)}

					<SubForm name="studentEthnicityForm">
						<Grid item xs={12}>
							<EthnicityCheckboxList subText="Optional" />
						</Grid>
					</SubForm>
					{studentType === StudentType.HIGH_SCHOOL && (
						<StudentDataInputs active={active} userData={userData} />
					)}
					{studentType === StudentType.COLLEGE && (
						<CollegeStudentDataComponents userData={userData} active={active} />
					)}
				</SubForm>
				{studentType === StudentType.HIGH_SCHOOL && <StudentCollegeAndPreferencesInputs />}
				<Grid item xs={12}>
					<SubForm name="student">
						<SubForm name="studentAddressForm">
							<Typography variant="h6">Contact Information</Typography>
							<AddressBlock
								address1Label="Street Address"
								required={active}
								hideAddress2Input
								hideCityInput
								hideStateInput
								student={userData}
							/>
						</SubForm>
						<SubForm name="studentDataForm">
							<PhoneNumberInput required={active} />
						</SubForm>
					</SubForm>
				</Grid>
				<Grid item xs={12}>
					<RegisterButton
						data-qa="create-account-button"
						$noneTextTransform
						$boldFontWeight
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						disabled={isButtonDisabled}
						onClick={onClick}
					>
						{totalSteps === currentStep ? 'Finish' : 'Next'}
					</RegisterButton>
				</Grid>
				<Grid item xs={12}>
					<LegalTextAndLinks configs={legalTextConfig} />
				</Grid>
			</Grid>
		</DataFlowContainer>
	);
};

export default ScholarshipInfoPage;

import React, { FC } from 'react';
import * as R from 'ramda';
import { StudentCollegeListLocationTrackingValue } from '@src/common/util/studentcollege';
import { Grid, Typography, Button } from '@material-ui/core';
import CollegeCardCarousel from '@src/common/components/CollegeCardCarousel';
import { AutomationNameDefault } from '@src/common/util/automation';
import withStyleOptions from '@util/style/styleOptions';
import { styled } from '@cappex/theme';
import { CollegeRecommendationResult } from '@util/collegerecommendation/constants';

export const NoRecommendationsGrid = styled(Grid)`
	height: 9.625rem;
`;

const NoRecommendationsGridItem = styled(Grid)`
	width: 17rem;
`;

export const ShowMoreButton = withStyleOptions(Button);

export interface StudentCollegeListCarouselProps {
	maxCardsVisible?: number;
	recommendedColleges: CollegeRecommendationResult[];
	hadRecommendations: boolean;
	refreshCollegeRecommendations: () => void;
	className?: string;
	dismissRecommendation: (collegeId: number) => void;
	locationTrackingvalue?: StudentCollegeListLocationTrackingValue;
}

const StudentCollegeListCarousel: FC<StudentCollegeListCarouselProps> = ({
	recommendedColleges,
	hadRecommendations,
	refreshCollegeRecommendations,
	className,
	dismissRecommendation,
	locationTrackingvalue = StudentCollegeListLocationTrackingValue.COLLEGE_LIST,
	maxCardsVisible = 4,
}) => {
	if (!R.isEmpty(recommendedColleges)) {
		return (
			<CollegeCardCarousel
				collegeData={recommendedColleges}
				locationTrackingValue={locationTrackingvalue}
				maxCardsVisible={maxCardsVisible}
				maxCardsInCarousel={8}
				className={className}
				displayDismiss
				onDismissClick={dismissRecommendation}
			/>
		);
	}

	return (
		<NoRecommendationsGrid
			container
			alignItems="center"
			justifyContent="center"
			direction="column"
			spacing={1}
			className={className}
		>
			<NoRecommendationsGridItem item>
				<Typography
					color="textSecondary"
					align="center"
					data-qa={AutomationNameDefault.collegeCarouselNoResults}
				>
					{hadRecommendations
						? 'It looks like you went through all of your recommendations!'
						: 'Keep building your college list so we can recommend more schools.'}
				</Typography>
			</NoRecommendationsGridItem>
			{hadRecommendations && (
				<NoRecommendationsGridItem item>
					<ShowMoreButton
						type="button"
						$boldFontWeight
						$noneTextTransform
						fullWidth
						variant="contained"
						color="primary"
						onClick={refreshCollegeRecommendations}
						data-qa={AutomationNameDefault.collegeCarouselShowMore}
					>
						Show me more
					</ShowMoreButton>
				</NoRecommendationsGridItem>
			)}
		</NoRecommendationsGrid>
	);
};

export default StudentCollegeListCarousel;

import React, { FC } from 'react';
import { LabelProps } from 'recharts';
import { AutomationNameDefault } from '@util/automation';

type ChanceChartProps = {
	text: string;
	color: string;
	textColor: string;
	textOffsetX: number;
} & LabelProps;

// SHJ 11/7/2019: Embedding an svg in recharts resulted in a safari compatibility issue.
// The resoltuion was returning the svg directly, instead of a reference to an external image resource.
const ChanceChartLabel: FC<ChanceChartProps> = ({
	width,
	x,
	y,
	text,
	color,
	textColor = '#FFFFFF',
	textOffsetX,
}) => (
	<svg
		width="80px"
		height="36px"
		viewBox="0 0 80 36"
		version="1.1"
		x={Math.max(0, Number(width) + Number(x) - 40)}
		y={Number(y) - 40}
	>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M6,28 C2.6862915,28 4.05812251e-16,25.3137085 0,22 L0,6 C-4.05812251e-16,2.6862915 2.6862915,6.08718376e-16 6,0 L74,0 C77.3137085,-6.08718376e-16 80,2.6862915 80,6 L80,22 C80,25.3137085 77.3137085,28 74,28 L47.9999875,28 C44.8023301,28 42.4916785,36 40.1763895,36 C37.8611005,36 35.2029185,28 31.9999875,28 L31.9999875,28 L6,28 Z"
				id="Combined-Shape"
				fill={color}
			/>
			<text
				fontFamily="NunitoSans-Bold, Nunito Sans"
				fontSize="18"
				fontWeight="bold"
				letterSpacing="-0.2"
				fill={textColor}
				data-qa={AutomationNameDefault.chancesChartLabel}
			>
				<tspan x={textOffsetX} y="20">
					{text}
				</tspan>
			</text>
		</g>
	</svg>
);

export default ChanceChartLabel;

import React, { FC, MouseEventHandler } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { styled } from '@cappex/theme';
import { AutomationNameGeneric } from '@src/common/util/automation';
import { SearchMenuItem } from '@cappex/search';
import { connectHitInsights, WrappedInsightsClient } from 'react-instantsearch-core';
import defaultClient from 'search-insights';
import { collegeConvertEventName } from '@src/common/components/search/constants';

const WrappingTypography = styled(Typography)`
	white-space: normal;
`;

type LocationData = {
	city: string;
	state: string;
	country: string;
};

// Have to redefine OptionProps' InnerProps since it creates conflicting props with the MenuItem
type LiOptionProps = {
	label: string;
	isFocused: boolean;
	innerRef: any;
	data: LocationData & { hit?: any };
	insights: WrappedInsightsClient;
	hit: any;
	innerProps: Partial<{
		id?: string;
		key?: string;
		onClick?: MouseEventHandler<HTMLLIElement>;
		onMouseMove?: MouseEventHandler<HTMLLIElement>;
		onMouseOver?: MouseEventHandler<HTMLLIElement>;
		tabIndex?: number;
	}>;
};

const StudentCollegeSearchItem: FC<Partial<LiOptionProps>> = ({
	label,
	innerProps: { onClick, ...innerProps },
	innerRef,
	isFocused,
	insights,
	data: { city, state, country },
}) => (
	<SearchMenuItem
		buttonRef={innerRef}
		selected={isFocused}
		onClick={e => {
			insights('convertedObjectIDsAfterSearch', { eventName: collegeConvertEventName });
			return onClick(e);
		}}
		{...innerProps}
	>
		<Grid container direction="column">
			<Grid item>
				<WrappingTypography variant="body1" data-qa={`${AutomationNameGeneric.menuItem}-title`}>
					{label}
				</WrappingTypography>
			</Grid>
			<Grid item>
				<Typography
					variant="body2"
					color="textSecondary"
					data-qa={`${AutomationNameGeneric.menuItem}-location`}
				>
					{country === 'USA' ? `${city}, ${state}` : country}
				</Typography>
			</Grid>
		</Grid>
	</SearchMenuItem>
);

const ConnectedItem = connectHitInsights(defaultClient)(StudentCollegeSearchItem) as FC<
	Partial<LiOptionProps>
>;

const StudentCollegeSearchItemWrapper: FC<Partial<LiOptionProps>> = ({
	data: { hit, ...data },
	...props
}) => <ConnectedItem hit={hit} data={data} {...props} />;
export default StudentCollegeSearchItemWrapper;

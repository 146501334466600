import React, { FC, useState, useEffect, useMemo, useContext, useCallback } from 'react';
import * as R from 'ramda';
import {
	Student,
	EMPTY_STUDENT,
	parseStudent,
	JsonStudentForm,
} from '@util/student/studentDataUtil';
import request, {
	WebResponse,
	RequestMethod,
	JsonAcceptHeader,
	JsonContentTypeHeader,
	ErrorMessages,
} from '@cappex/request';
import getEndpoint, { FormKeyedData } from '@util/request';
import useFailedAuth from '@util/auth/hooks/useFailedAuth';
import { SnackbarContext, SnackbarType } from '@src/common/components/SnackbarManager';
import StudentContext, { StudentView } from '..';
import { enableAdcView } from '@src/features/environment';
import { studentViewUrlName } from '../constants';
import useUrlParam from '../../hooks/useUrlParam';
import decideStudentType from '../util/decideStudentView';
import updateApplicationDataLayer from '../../dataLayer/updateApplicationDataLayer';
import {
	LEVEL_OF_DEGREE_SEEKING_ID_HEAP_LABEL,
	STUDENT_TYPE_ID_HEAP_LABEL,
} from '@src/common/constants/heap';

type StudentDataWebResponse = WebResponse<FormKeyedData, JsonStudentForm>;

export const createRefreshStudent = (
	setStudent: (student: Student) => void,
	setView: (view: StudentView) => void,
	failAuth: () => void,
	onError: () => void
) => async (refreshView = false) => {
	try {
		const { data } = await request<StudentDataWebResponse>({
			url: getEndpoint(`/student/v2/retrieve`),
			method: RequestMethod.GET,
			withCredentials: true,
			headers: [JsonAcceptHeader, JsonContentTypeHeader],
		});

		if (data === undefined) {
			return;
		}

		if (data.meta.success && !R.isNil(data.response)) {
			const studentData = parseStudent(data.response);
			setStudent(studentData);

			// Only change the view from default IF the feature is on

			if (enableAdcView && refreshView) {
				decideStudentType(studentData);
				setView(decideStudentType(studentData));
			}

			updateApplicationDataLayer(studentData);
		} else {
			onError();
		}
	} catch (err) {
		if (err.response && (err.response.statusCode === 401 || err.response.statusCode === 403)) {
			failAuth();
			return;
		}

		onError();
	}
};

const StudentProvider: FC = ({ children }) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const studentViewTypeFromUrl = useUrlParam(studentViewUrlName);

	const [student, setStudent] = useState(EMPTY_STUDENT);

	const { levelOfDegreeSeekingId, studentTypeId } = student;

	useEffect(() => {
		window?.heap?.addUserProperties?.({ [STUDENT_TYPE_ID_HEAP_LABEL]: studentTypeId });
	}, [studentTypeId]);

	useEffect(() => {
		window?.heap?.addUserProperties?.({
			[LEVEL_OF_DEGREE_SEEKING_ID_HEAP_LABEL]: levelOfDegreeSeekingId,
		});
	}, [levelOfDegreeSeekingId]);

	const [view, setView] = useState(
		(Number(studentViewTypeFromUrl) as StudentView) || StudentView.UNDERGRADUATE
	);

	const failAuth = useFailedAuth();

	const onError = useCallback(() => {
		openSnackbar({
			snackbarType: SnackbarType.Error,
			message: ErrorMessages.unknown,
		});
	}, [openSnackbar]);

	const refreshStudent = useMemo(
		() => createRefreshStudent(setStudent, setView, failAuth, onError),
		[failAuth, onError]
	);

	useEffect(() => {
		// refresh student data on-load, but only change the view if not provided
		refreshStudent(studentViewTypeFromUrl === null);
	}, [refreshStudent, studentViewTypeFromUrl]);

	const studentContextValue = useMemo(
		() => ({ student, setStudent, refreshStudent, view, setView }),
		[student, setStudent, refreshStudent, view, setView]
	);

	return <StudentContext.Provider value={studentContextValue}>{children}</StudentContext.Provider>;
};

export default StudentProvider;

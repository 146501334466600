import { styled } from '@cappex/theme';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	FormControl,
	Grid,
	Box,
	Card,
	CardMedia,
	Typography,
	CardActionArea,
	FormHelperText,
} from '@material-ui/core';
import React, { FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { QuizContext, QuizEvent, QuizEventType } from '..';
import { AutomationNameDefault } from '../../automation';
import useFormValidation from '../../hooks/useFormValidation';
import { SubFormContext } from '@src/common/components/BaseValidationForm';
import useMergedFormQuizDispatch, {
	QuizDispatchableConfig,
} from '../util/useMergedFormQuizDispatch';
import * as R from 'ramda';
import { validateRequired } from '@common/components/BaseFormSelect';
import { FormContext } from '@util/validation/form';
import { FormNames, SupportedCustomQuizDataFields } from '@cappex/constants';
import customQuizQuestionMappingNew from '@src/features/dataflow/util/customQuizQuestionMapping';

export type QuizAnswerCustomMapping = {
	dataField: string;
	value: string[];
}

type ImageSelectConfig = QuizDispatchableConfig & {
	image: string;
	label?: string;
	id: string | number;
	// Technically 1 question can only map to 1 data field. Trying to 'future proof' by allowing multiple.
	customMapping?: QuizAnswerCustomMapping[];
	name?: string
};

type GenericImageSelectProps = {
	required?: boolean;
	label?: string;
	name?: string;
	extra?: {
		multi?: boolean;
		selectConfig: ImageSelectConfig[];
	};
};

type SelectableImageProps = ImageSelectConfig & {
	selected?: boolean;
	dispatch: (id: string | number, event: QuizEvent) => void;
};

const SelectableCard = styled(Card)`
    position: relative;
    border: 2px solid transparent;
`;
const CircleBox = styled(({ selected, ...props }) => <Box {...props} />)`
    height: 1.5rem;
    width: 1.5rem;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    background: ${({ selected, theme }) =>
            selected ? theme.palette.primary.main : theme.palette.common.white};
    border-radius: 50%;
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    overflow: hidden;
`;

const RelativeCardMedia = styled(({ selected, ...props }) => <CardMedia {...props} />)`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const StyledIcon = styled(FontAwesomeIcon)`
    margin: auto;
    display: block;
    padding-top: 0.3rem;
`;

const LabelPadding = styled.div`
    padding: 0.5rem 0 0 0;
`;

const MediaContainer = styled(({ selected, ...props }) => <div {...props} />)`
    position: relative;
    border: 2px solid ${({ selected, theme }) => (selected ? theme.palette.primary.main : 'white')};
    border-radius: 0.5rem;
    overflow: hidden;

    &::after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }
`;

const ErrorText = styled(FormHelperText)`
    margin-left: 0.75rem;
`;

const SelectCircle: FC<{ selected?: boolean }> = ({ selected }) => (
	<CircleBox selected={selected}>
		<StyledIcon icon={faCheck} color="white" />
	</CircleBox>
);

const SelectableImage: FC<SelectableImageProps> = ({
																										 image,
																										 label,
																										 dispatch,
																										 selected,
																										 values,
																										 id,
																										 customMapping,
																										 name
																									 }) => {
	const { setFormValue, setError, getValue} = useContext(FormContext);

	const addToCustomMappingForm = () => {
		if (customMapping != null && customMapping.length > 0) {
			customMapping.forEach(mapping => {
				if (
					!Object.values(SupportedCustomQuizDataFields).includes(
						mapping.dataField as SupportedCustomQuizDataFields
					)
				) {
					setError(name, ['Attempting to set invalid data field']);
				}
				const paths = customQuizQuestionMappingNew(mapping.dataField)

				setFormValue(paths[paths.length-1], mapping.value);
			});
		}
	};

	const removeFromCustomMappingForm = () => {
		if(customMapping != null && customMapping.length > 0){
			customMapping.forEach(mapping => {
				const {[mapping.dataField]:_, ...remainder} = getValue(FormNames.customQuizMappingForm)[FormNames.customQuizMappingForm]
				setFormValue(FormNames.customQuizMappingForm, remainder)
			})
		}
	}

	return (<Grid item xs={6}>
			<SelectableCard elevation={0}>
				<CardActionArea
					onClick={() => {
						dispatch(id, {
							type: selected ? QuizEventType.REVOKE : QuizEventType.SEND,
							values,
						});
						if(!selected) {
							addToCustomMappingForm()
						} else {
							removeFromCustomMappingForm()
						}
					}}
					data-qa={AutomationNameDefault.selectableImage}
				>
					<MediaContainer selected={selected}>
						<RelativeCardMedia component="img" image={image} />
						<SelectCircle selected={selected} />
					</MediaContainer>
				</CardActionArea>
				{label && (
					<LabelPadding>
						<Typography variant="body2" color="textSecondary">
							{label}
						</Typography>
					</LabelPadding>
				)}
			</SelectableCard>
		</Grid>
	);
};

const INTERNAL = 'internal_id';

const GenericImageSelect: FC<GenericImageSelectProps> = ({
																													 required = false,
																													 label,
																													 name,
																													 extra: { multi, selectConfig } = { multi: false, selectConfig: [] },
																												 }) => {
	const { dispatch } = useContext(QuizContext);
	const { path } = useContext(SubFormContext);
	const listRef = useRef(null);

	const initialValueObj = useMemo(() => ({ [name]: [null] }), [name]);
	const validator = useCallback(input => validateRequired(name, required)(input), [name, required]);

	const { value, setValue, error } = useFormValidation({
		path,
		name,
		validator,
		fieldRef: listRef,
		initialValue: initialValueObj,
		removeUpdateFields: true,
	});

	// In case we have no name and don't want the form to be polluted with extra data
	const [nonFormValue, setNonFormValue] = useState({ [INTERNAL]: [null] });
	const selectedIds = R.isNil(name) ? nonFormValue[INTERNAL] : value?.[name] || [];

	const patchedDispatch = useMergedFormQuizDispatch({
		dispatch,
		multi,
		selectConfig,
		setValue: R.isNil(name) ? setNonFormValue : setValue,
		name: R.isNil(name) ? INTERNAL : name,
		selectedIds,
	});

	return (
		<>
			<Typography align="left" variant="h6">
				<b>{label}</b>
			</Typography>
			<FormControl fullWidth error={!!error}>
				<Grid container spacing={3}>
					{selectConfig.map(({ id, image, values, label: imageLabel , customMapping}, index) => (
						<SelectableImage
							image={image}
							values={values}
							label={imageLabel}
							dispatch={() =>
								patchedDispatch(id || index, {
									type: selectedIds.includes(id || index)
										? QuizEventType.REVOKE
										: QuizEventType.SEND,
									values,
								})
							}
							id={id || index}
							key={label}
							selected={selectedIds.includes(id || index)}
							customMapping={customMapping}
							name={name}
						/>
					))}
				</Grid>
				<ErrorText>{error}</ErrorText>
			</FormControl>
		</>
	);
};
export default GenericImageSelect;
import React, { FC, useCallback, useContext, useMemo, useRef } from 'react';
import { styled } from '@cappex/theme';
import { Chip, FormControl, Grid, Typography } from '@material-ui/core';
import { AutomationNameGeneric } from '@common/util/automation';
import useFormValidation from '@common/util/hooks/useFormValidation';
import { StepComponentProps } from '../util/steps';
import { FormNames } from '@cappex/constants';
import { SubFormContext } from './BaseValidationForm';
import FormHelperText from '@material-ui/core/FormHelperText';
import { validateRequired } from '@common/components/BaseFormCheckbox';

interface RadioChipProps {
	label: string;
	value: string | number | boolean;
	radioName: FormNames;
	checked: boolean;
	onChange: (event: React.ChangeEvent<{}>) => void;
	xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	xl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

const RadioChip = styled(Chip)`
	background-color: ${props => props.theme.palette.common.white};
	border: 1px solid ${props => props.theme.palette.ink.transparent};
	color: ${props => props.theme.palette.ink.dark}
	padding: 0 0.5rem;
	width: 100%;

	&:hover {
		background-color: ${props => props.theme.palette.ink.transparent};
	}

	&.checked {
		color: ${props => props.theme.palette.ink.dark};
		border: 0px solid ${props => props.theme.palette.primary.main};
		font-weight: bold;
		background-color: ${props => props.theme.palette.primary.transparent};
	}
`;

const RadioChipContainer: FC<RadioChipProps> = ({
	label,
	value,
	radioName,
	checked,
	onChange,
	xs,
	sm,
	md,
	lg,
	xl,
}) => {
	const radioId = `${radioName}_${value}`;
	const chipOnClick = () => document.getElementById(radioId).click();
	return (
		<Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
			<RadioChip
				clickable
				onClick={chipOnClick}
				label={label}
				className={checked ? 'checked' : ''}
			/>
			<input
				type="radio"
				hidden
				id={radioId}
				name={radioName}
				value={value.toString()}
				checked={checked}
				onChange={onChange}
			/>
		</Grid>
	);
};

const Title = styled(Typography).attrs({
	variant: 'h6',
	gutterBottom: true,
})`
	font-weight: bold;
`;
const OptionalGutterGrid = styled(({ gutter, ...props }) => <Grid {...props} />)`
	${({ gutter }) => (gutter ? 'margin-bottom: 1rem;' : '')}
`;

export type ChipColumnsDefinition = {
	xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	xl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
};

export interface Props {
	defaultValue?: string;
	title?: string;
	radioName: FormNames;
	options: RadioOption[];
	automationName?: string;
	chipColumns?: ChipColumnsDefinition;
	gutter?: boolean;
	required?: boolean;
	onChange?: (value: string) => void;
}

export type RadioOption = {
	displayValue: string;
	value: string | number | boolean;
	onSelect?: () => void;
};

const RadioChipSelector: FC<Props & StepComponentProps> = ({
	defaultValue,
	title,
	radioName,
	options,
	complete,
	automationName = AutomationNameGeneric.chips,
	chipColumns: { xs, sm, md, lg, xl } = {},
	gutter,
	required,
	onChange = () => {},
}) => {
	const listRef = useRef(null);
	const { path } = useContext(SubFormContext);

	const validator = useCallback(input => validateRequired(radioName, required)(input), [
		radioName,
		required,
	]);
	const initialValueObj = useMemo(() => ({ [radioName]: defaultValue }), [defaultValue, radioName]);
	const { value, setValue, error } = useFormValidation({
		path,
		name: radioName,
		validator,
		fieldRef: listRef,
		initialValue: initialValueObj,
	});

	const radioValue = value[radioName];

	if (defaultValue !== undefined) {
		complete();
	}

	const onSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange(event.target.value);
		setValue({ [radioName]: event.target.value });
		complete(event.target.value);
	};

	return (
		<>
			{title && <Title>{title}</Title>}
			<FormControl margin="dense" fullWidth variant="outlined" error={!!error} ref={listRef}>
				<OptionalGutterGrid gutter={gutter} container spacing={2} data-qa={automationName}>
					{options.map(({ displayValue, value: optionValue, onSelect }) => (
						<RadioChipContainer
							key={displayValue}
							radioName={radioName}
							label={displayValue}
							value={optionValue}
							checked={radioValue === optionValue.toString()}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								onSelectChange(event);
								onSelect && onSelect();
							}}
							xs={xs}
							sm={sm}
							md={md}
							lg={lg}
							xl={xl}
						/>
					))}
					<FormHelperText>{error}</FormHelperText>
				</OptionalGutterGrid>
			</FormControl>
		</>
	);
};

export default RadioChipSelector;

import React, { FC, useEffect, useContext } from 'react';
import { QueueAction, ModalContext } from '@src/common/util/steps/components/ModalStepFlow';
import AuthRefresh from '@src/common/util/auth/components/AuthRefresh';
import { Redirect as ReactRouterRedirect, useHistory } from 'react-router-dom';
import { StepComponentProps } from '@src/common/util/steps';
import { DataFlowStepConfig } from '../../constants/types';
import AuthContext from '@src/common/util/auth';
import { env } from '@src/features/environment';
import { FormContext } from '@src/common/util/validation/form';
import { FormNames, DataFlowStepName } from '@cappex/constants';

// TODO: remove when CAPPEX-3554 is done
declare const newrelic: any;

const DoRedirectComponent = ({ complete, ...props }) => {
	useEffect(complete, [complete]);
	return <ReactRouterRedirect to={(props as any).to} />;
};

const RedirectStep: FC<StepComponentProps<any, any, DataFlowStepConfig>> = ({ data, active }) => {
	const { queueModal } = useContext(ModalContext);
	const { attemptedLocation } = useContext(AuthContext);
	const history = useHistory();
	const { getValue } = useContext(FormContext);

	useEffect(
		() =>
			queueModal(QueueAction.PREPEND, DataFlowStepName.Redirect, DoRedirectComponent, {
				to: attemptedLocation || (data as any).to,
			}),
		[queueModal, attemptedLocation, data]
	);

	const onAuthFail = () => {
		history.push('/login');

		// TODO: remove when CAPPEX-3554 is done
		if (env === 'prod') {
			const dataFlowCode = getValue(FormNames.dataFlowCode)[FormNames.dataFlowCode];
			const error = new Error(
				`Authentication error during redirect. DataFlowCode: ${dataFlowCode}`
			);

			if (typeof newrelic !== 'undefined') {
				newrelic.noticeError(error);
			} else {
				throw error;
			}
			throw error;
		}
	};

	return active ? <AuthRefresh onAuthFail={onAuthFail} /> : <div />;
};
export default RedirectStep;

import * as R from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import BaseValidationForm from '@src/common/components/BaseValidationForm';
import { DataFlowType } from '@cappex/constants';
import request, { JsonAcceptHeader, JsonContentTypeHeader, RequestMethod } from '@cappex/request';
import getEndpoint from '@util/request';
import routeBasedOneStepAtATimeFactory from '@src/common/util/steps/components/RouteBasedOneStepAtATimeFlow';
import { FormContext, withFormValidation } from '@src/common/util/validation/form';
import registrationPathConfigs, { RegistrationPathConfiguration } from '../constants/registrationPathConfigs';
import { DataFlowConfig } from '../../dataflow/constants/types';
import DataFlowContext from '../../dataflow/util/DataFlowContext';
import stepMapping from '../../dataflow/util/stepMapping';
import updateFlexRegistrationDataLayer from '../../../common/util/dataLayer/updateFlexRegistrationDataLayer';
import QuizContextProvider from '@src/common/util/quiz';

// Create our Registration flow that just goes forward & backwards by providing our mapper
const FlowComponent = routeBasedOneStepAtATimeFactory(stepMapping);

type RegistrationFlowProps = {
	initialConfig: DataFlowConfig;
};

const RegistrationFlow: FC<RegistrationFlowProps> = ({ initialConfig }) => {
	const [checkedMidway, setCheckedMidway] = useState(false);
	const initialPath = initialConfig.steps[0].data.name;

	const { pathname } = useLocation();
	const history = useHistory();
	const match = pathname.match(/\/register\/([\w-]+)\/([\w-]+)/);
	let subPath;
	let mainPath;
	if (match !== null) {
		[, mainPath, subPath] = match;
	}
	useEffect(() => {
		if (!checkedMidway) {
			setCheckedMidway(true);
			if (!initialConfig.canStartMidway && subPath && subPath !== initialPath) {
				history.replace(`/register/${mainPath}/${initialPath}${window.location.search}`);
			}
		}
	}, [
		mainPath,
		subPath,
		checkedMidway,
		pathname,
		initialConfig.canStartMidway,
		initialPath,
		history,
	]);

	return (
		<FlowComponent
			initialConfig={initialConfig}
			initialId={`${mainPath}.${initialPath}`}
			initialDataFlowType={DataFlowType.REGISTRATION}
		/>
	);
};

const createDoRegistration = ({
	getFormValues,
	preHook,
	postHook,
	errorHook,
	resetHooks,
}) => () => {
	const requestData = {
		...getFormValues(true, true),
	};
	resetHooks();
	preHook();

	const updateRequestData = { ...requestData };

	request<any>({
		url: getEndpoint('/data-flow/v1/submit-data'),
		method: RequestMethod.POST,
		data: updateRequestData,
		withCredentials: true,
		headers: [JsonAcceptHeader, JsonContentTypeHeader],
	})
		.then(res => res.data)
		.then(data => {
			postHook(data);
			if (!data?.meta?.success) return;
			updateFlexRegistrationDataLayer(requestData);
		})
		.catch(errorHook);
};

export const createRoutes = baseUrl =>
	R.pipe(
		R.mapObjIndexed((config: RegistrationPathConfiguration, name) => (
			<Route
				key={`${baseUrl}/${name}`}
				path={`${baseUrl}/${name}`}
				render={() => (
					<>
						{/* <HiddenInput */}
						{/*	name={FormNames.customQuizMappingForm} */}
						{/*	id="customQuizQuestionMappings" */}
						{/*	initialValue={emptyObject} */}
						{/*	automationName="hidden-input" */}
						{/* /> */}
						<QuizContextProvider results={config.config?.results}>
							<RegistrationFlow key={name} initialConfig={config.config} />
						</QuizContextProvider>
					</>
				)}
			/>
		)),
		R.values
	)(registrationPathConfigs);

const EMPTY = () => () => {};

const FormFlow = () => {
	const match = useRouteMatch();
	const { getFormValues } = useContext(FormContext);

	const registrationRoutes = useMemo(() => createRoutes(match.url), [match.url]);

	const [preHook, setPreHook] = useState(() => () => {});
	const [postHook, setPostHook] = useState(() => () => {});
	const [errorHook, setErrorHook] = useState(() => () => {});
	const resetHooks = () => {
		setPreHook(EMPTY);
		setPostHook(EMPTY);
		setErrorHook(EMPTY);
	};

	const onValid = useMemo(
		() => createDoRegistration({ getFormValues, preHook, postHook, errorHook, resetHooks }),
		[getFormValues, preHook, postHook, errorHook]
	);
	const value = useMemo(() => ({ setPreHook, setPostHook, setErrorHook }), [
		setPreHook,
		setPostHook,
		setErrorHook,
	]);

	return (
		<BaseValidationForm data-qa="form" id="registration" name="registration" onValid={onValid}>
			<DataFlowContext.Provider value={value}>
				<Switch>
					{[
						...registrationRoutes,
						<Route key="/" path="/" render={() => <Redirect to="/register/core/main1" />} />,
					]}
				</Switch>
			</DataFlowContext.Provider>
		</BaseValidationForm>
	);
};
const registrationFieldToUrlParameterMapping = {
	// Explicitly disallow PII
	email: null,
	password: null,
	address1: null,
	address2: null,
	city: null,
	postalCode: null,
	firstName: null,
	lastName: null,
	countryCode: null,
	phone: null,

	// Other data should be fine
	costId: 'costId',
	levelOfInstitutionId: 'levelOfInstitutionId',
	distanceId: 'distanceId',
	international: 'international',
	countryId: 'countryId',
	provinceRegion: 'provinceRegion',
	stateId: 'stateId',
	collegeGpa: 'collegeGpa',
	collegeGradMonth: 'collegeGradMonth',
	collegeGradYear: 'collegeGradYear',
	collegeStartTermId: 'collegeStartTermId',
	collegeStartYear: 'collegeStartYear',
	collegeTransferTermId: 'collegeTransferTermId',
	collegeTransferYear: 'collegeTransferYear',
	currentCollegeId: 'currentCollegeId',
	openToTransfer: 'openToTransfer',
	genderId: 'genderId',
	annualFamilyIncomeLevelId: 'annualFamilyIncomeLevelId',
	interestedInFinancialAid: 'interestedInFinancialAid',
	classRankId: 'classRankId',
	courseRigorId: 'courseRigorId',
	gpaUnweighted: 'gpaUnweighted',
	gpaWeighted: 'gpaWeighted',
	highSchoolGradMonth: 'highSchoolGradMonth',
	highSchoolGradYear: 'highSchoolGradYear',
	highSchoolId: 'highSchoolId',
	desiredCompletionTimeframeId: 'desiredCompletionTimeframeId',
	levelOfDegreeSeekingId: 'levelOfDegreeSeekingId',
	startTimeframeId: 'startTimeframeId',
	yearsOfWorkExperience: 'yearsOfWorkExperience',
	actComposite: 'actComposite',
	actEnglish: 'actEnglish',
	actMath: 'actMath',
	actReading: 'actReading',
	actScience: 'actScience',
	actWriting: 'actWriting',
	gmatTotal: 'gmatTotal',
	greAnalyticalWriting: 'greAnalyticalWriting',
	greQuantitativeReasoning: 'greQuantitativeReasoning',
	greVerbalReasoning: 'greVerbalReasoning',
	preAct: 'preAct',
	psatNmsqt: 'psatNmsqt',
	satComposite: 'satComposite',
	satMath: 'satMath',
	satReadWrite: 'satReadWrite',
	studentTypeId: 'studentTypeId',
	collegeSizeIds: 'collegeSizeIds',
	regionIds: 'regionIds',
	stateIds: 'stateIds',
	majorCipCodes: 'majorCipCodes',
	townSizeIds: 'townSizeIds',
	studentCbos: 'studentCbos',
	modalityIds: 'modalityIds',
};

export default withFormValidation(FormFlow, registrationFieldToUrlParameterMapping);
import * as R from 'ramda';
import React, { useEffect, useMemo, useState, useCallback, useContext } from 'react';
import useCloudReferenceData from '@util/hooks/useCloudReferenceData';
import { ReferenceDataTypes } from '@cappex/constants';
import { WamcIneligibleReason } from '@util/wamc/constants';
import ConsentManager from '@src/features/consent/main';
import { SnackbarContext, SnackbarProps } from '@src/common/components/SnackbarManager';
import {
	StudentCollegeConnection,
	StudentCollegeListContext,
	StudentCollegeListStatus,
	StudentCollegeConnectionType,
	AddListItemParams,
	StudentCollegeListExperienceTrackingValue,
} from '..';
import { getList, removeFromList, addToList } from '../util/StudentCollegeListUtil';
import { QueueAction, ModalContext } from '../../steps/components/ModalStepFlow';
import StudentContext, { StudentView } from '../../studentContext';
import { JitEvent } from '../../jit/constants';
import EventBus, { EventTypes } from '@src/features/app/util/EventBus';

const wamcFor = (bucket: string) => ({
	bucket,
	score: 0.1,
});

const convertStudentViewToTrackingView = (
	view: StudentView
): StudentCollegeListExperienceTrackingValue => {
	switch (view) {
		case StudentView.ADULT_COMPLETER:
			return StudentCollegeListExperienceTrackingValue.ADULT_COMPLETER;
		case StudentView.UNDERGRADUATE:
			return StudentCollegeListExperienceTrackingValue.UNDERGRADUATE;
		case StudentView.GRADUATE:
			return StudentCollegeListExperienceTrackingValue.GRADUATE;
		default:
			throw new Error('Provided Invalid Student View for Tracking');
	}
};

const UNDEFINED_COLLEGE: StudentCollegeConnection = {
	collegeData: {
		city: undefined,
		country: undefined,
		logoUrl: undefined,
		heroImageUrl: undefined,
		name: undefined,
		stateAbbr: undefined,
	},
	levelOfInterest: undefined,
	collegeApplicationStatus: undefined,
	collegeId: undefined,
	collegeListStatus: undefined,
	createdOn: undefined,
	id: undefined,
	studentId: undefined,
	wamcIneligibleReason: WamcIneligibleReason.NONE,
	wamcScore: undefined,
};

const UNSAVED_COLLEGE_ID = -1;

const STUDENT_COLLEGE_LIST_KEY = 'student-college-list-modal';

export const createAddListItem = (
	addNewListItem: (collegeId: number) => void,
	refresh: () => void,
	openSnackbar: (props: Partial<SnackbarProps>) => void,
	setIsSavingCollege: React.Dispatch<React.SetStateAction<number | null>>,
	baseTrackingData: Partial<AddListItemParams['trackingData']> = {}
) => async ({
	collegeId,
	trackingData,
	savedMessage,
	cannotSaveMessage,
	onSuccess,
}: AddListItemParams) => {
	try {
		setIsSavingCollege(collegeId);
		addNewListItem(collegeId);

		const collegeListId = await addToList(
			collegeId,
			{
				...baseTrackingData,
				...trackingData,
			},
			StudentCollegeConnectionType.GENERAL
		);

		if (R.isNil(collegeListId)) {
			throw new Error();
		}

		EventBus.emit(EventTypes.JIT_CHECK, {
			eventType: JitEvent.COLLEGE_INQUIRY,
			collegeIds: [collegeId.toString()],
		});

		openSnackbar({ message: savedMessage || 'College saved' });
		onSuccess && onSuccess(collegeId);
		setIsSavingCollege(null);
	} catch (err) {
		openSnackbar({ message: cannotSaveMessage || 'Could not save college' });
		setIsSavingCollege(null);
	}

	refresh();
};

export const createRemoveListItem = (
	removeItemFromList: (collegeListId: number) => void,
	refresh: () => void,
	openSnackbar: (props: Partial<SnackbarProps>) => void
) => async (collegeListId: number) => {
	try {
		removeItemFromList(collegeListId);

		const success = await removeFromList(collegeListId);

		if (!success) {
			throw new Error();
		}

		openSnackbar({ message: 'College removed' });
	} catch (err) {
		openSnackbar({ message: 'Could not remove college' });
	}

	refresh();
};

const REACH_COLLEGE = id => ({ ...UNDEFINED_COLLEGE, id, wamcScore: wamcFor('Reach') });
const TARGET_COLLEGE = id => ({ ...UNDEFINED_COLLEGE, id, wamcScore: wamcFor('Target') });
const LIKELY_COLLEGE = id => ({ ...UNDEFINED_COLLEGE, id, wamcScore: wamcFor('Likely') });

const INITIAL_LOADING_LIST = [
	REACH_COLLEGE(1),
	REACH_COLLEGE(2),
	TARGET_COLLEGE(3),
	TARGET_COLLEGE(4),
	LIKELY_COLLEGE(5),
	LIKELY_COLLEGE(6),
];

const INIT_IDS_FOR_CONSENT_CHECK = [];
const INIT_PREV_LIST = [];

const StudentCollegeListProvider = ({ children }) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { queueModal } = useContext(ModalContext);
	const { view } = useContext(StudentContext);

	const [list, setList] = useState<StudentCollegeConnection[]>(INITIAL_LOADING_LIST);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isSavingCollege, setIsSavingCollege] = useState<number | null>(null);
	const [prevList, setPrevList] = useState(INIT_PREV_LIST);
	const [collegeIdsToCheckForConsent, setCollegeIdsToCheckForConsent] = useState<number[]>(
		INIT_IDS_FOR_CONSENT_CHECK
	);

	const onConsentModalClose = useCallback(() => {
		setCollegeIdsToCheckForConsent(INIT_IDS_FOR_CONSENT_CHECK);
	}, []);

	const [collegeApplicationStatuses] = useCloudReferenceData({
		dataType: ReferenceDataTypes.collegeApplicationStatus,
	});

	const refresh = useCallback(() => {
		getList([StudentCollegeListStatus.ACTIVE, StudentCollegeListStatus.ARCHIVED])
			.then(
				R.partition<StudentCollegeConnection>(
					R.propEq('collegeListStatus', StudentCollegeListStatus.ACTIVE)
				)
			)
			.then(([active]) => {
				setList(active);
				setIsLoaded(true);
			});
	}, []);

	const setListItem = useCallback(
		(updatedItem: StudentCollegeConnection) => {
			const collegeList = [...list];
			const collegeListItem = collegeList.find(item => item.id === updatedItem.id);
			Object.assign(collegeListItem, updatedItem);
			setList(collegeList);
		},
		[list]
	);

	const addNewListItem = useCallback((collegeId: number) => {
		const newCollege: Partial<StudentCollegeConnection> = {
			...UNDEFINED_COLLEGE,
			id: UNSAVED_COLLEGE_ID,
			collegeId,
			wamcIneligibleReason: undefined, // override this to prevent the college list from showing phantom groups
		};

		setList(R.append(newCollege));
	}, []);

	const removeItemFromList = useCallback((collegeListId: number) => {
		setList(R.reject(R.propEq('id', collegeListId)));
	}, []);

	const addListItem = useMemo(
		() =>
			createAddListItem(addNewListItem, refresh, openSnackbar, setIsSavingCollege, {
				EXPERIENCE: convertStudentViewToTrackingView(view),
			}),
		[refresh, addNewListItem, openSnackbar, view]
	);

	const removeListItem = useMemo(
		() => createRemoveListItem(removeItemFromList, refresh, openSnackbar),
		[openSnackbar, refresh, removeItemFromList]
	);

	useEffect(() => {
		// is loaded and list is not INIT LOADING LIST
		if (isLoaded && list !== INITIAL_LOADING_LIST) {
			// make sure newList has more than the previous list
			if (prevList !== INIT_PREV_LIST && list.length > prevList.length) {
				setCollegeIdsToCheckForConsent(prev => [
					...prev,
					...R.difference(
						list.map(li => li.collegeId),
						prevList.map(preLi => preLi.collegeId)
					),
				]);
			}
			setPrevList(list);
		}
	}, [isLoaded, list, prevList, setCollegeIdsToCheckForConsent]);
	const value = useMemo(
		() => ({
			list,
			refresh,
			setListItem,
			addListItem,
			removeListItem,
			collegeApplicationStatuses,
			isDefaultData: !isLoaded,
			isSavingCollege,
		}),
		[
			list,
			refresh,
			setListItem,
			addListItem,
			removeListItem,
			collegeApplicationStatuses,
			isLoaded,
			isSavingCollege,
		]
	);

	// Refresh to start with -- variable will not change as it's from a useCallback() with no parameters
	useEffect(() => refresh(), [refresh]);

	useEffect(() => {
		if (collegeIdsToCheckForConsent && collegeIdsToCheckForConsent.length > 0) {
			queueModal(QueueAction.APPEND, STUDENT_COLLEGE_LIST_KEY, ConsentManager, {
				collegeIdsToCheck: collegeIdsToCheckForConsent,
				onClose: onConsentModalClose,
			});
		}
	}, [
		collegeIdsToCheckForConsent,
		setCollegeIdsToCheckForConsent,
		queueModal,
		onConsentModalClose,
	]);

	return (
		<StudentCollegeListContext.Provider value={value}>
			{children}
		</StudentCollegeListContext.Provider>
	);
};

export default StudentCollegeListProvider;
